import {
  DIALOG_STATE,
  UPDATE_PROFILE,
  UPDATE_USER_INFO,
  UPDATE_USER_IMAGE_URL,
  UPDATE_MODELS,
  UPDATE_MODELS_AND_FAV_MODELS,
  ADD_FAV_MODEL,
  REMOVE_FAV_MODEL,
} from "../constants/actionTypes";
import api from "../api";
import axios from "axios";
import { BASE_URL } from "../config";

export const updateProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const updateUserImageUrl = (data) => ({
  type: UPDATE_USER_IMAGE_URL,
  payload: data,
});

export const updateUserInfo = (data) => ({
  type: UPDATE_USER_INFO,
  payload: data,
});

export const handleChangeDialogState = (data) => ({
  type: DIALOG_STATE,
  payload: data,
});

// export const updateLikedModels = (data) => ({
//   type: LIKED_MODEL,
//   payload: data,
// });

export const logIn = (
  postData,
  succ = () => { },
  err = () => { },
  handleEmailNotVerified = () => { }
) => {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/auth/login`, postData)
      .then((response) => {
        const { msg, confirmed } = response.data;
        if (
          !confirmed &&
          msg === "Please enter the code sent to your email address."
        ) {
          handleEmailNotVerified(msg);
        } else {
          const {
            data: { access_token, refresh_token, user },
          } = response.data;
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          localStorage.setItem("userId", user.id);
          dispatch(updateUserInfo(user));
          // dispatch(getProfileInfo());
          succ();
          return;
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        err(error);
      });
  };
};

export const submitOtp = (postData, succ = () => { }, err = () => { }) => {
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/auth/verify-email`, postData)
      .then((response) => {
        succ();
        return;
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        err(error);
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await api.post("/auth/logout");
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      window.open("/", "_self");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
};

export const submitProfile = (postData, succ = () => { }, err = () => { }) => {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await api.patch(`/user/${userId}/profile`, postData);
      const { data } = response.data;
      dispatch(updateProfile(data));
      succ(data);
    } catch (error) {
      console.error("An error occurred:", error);
      err(error);
    }
  };
};

export const getProfileInfo = (succ = () => { }, err = () => { }) => {
  return async (dispatch) => {
    try {
      const response = await api.get("/auth/myaccount");
      const { data } = response.data;
      dispatch(updateProfile(data));
      succ(data);

      const userImageResponse = await getFileUrl(data.user_img);
      dispatch(updateUserImageUrl(userImageResponse.data));
    } catch (error) {
      console.error("An error occurred:", error);
      err(error);
    }
  };
};

export const getFileUrl = async (modelId = '') => {
  try {
    const response = await axios.get(
      `${BASE_URL}/marketplace/model-token/${modelId}`
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred:");
  }
};

export const getUserInfo = async () => {
  try {
    const userId = localStorage.getItem("userId");

    const response = await api.get(`/user/${userId}/profile`);
    const { data } = response.data;
    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    return error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get("/auth/myaccount");
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return error;
  }
};

export const uploadModalInfo = (postData, succ = () => { }, err = () => { }) => {
  return async (dispatch) => {
    console.log("postData", postData)
    let apiData = {
      // s3_url: "",
      // icon: "",
      name: postData.name,
      // is_published: postData.isPublished,
      // is_approved: postData.isApproved,
      uploaded_file_info: postData.uploaded_file_info,
    };
    try {
      const response = await api.post("/model/", apiData);
      const { data } = response.data;
      succ(data);
    } catch (error) {
      console.error("An error occurred:", error);
      err(error);
    }
  };
};


export const publishModel = (
  contentId,
  succ = () => { },
  err = () => { }
) => {
  return async (dispatch) => {
    try {
      const response = await api.patch(
        `/model/${contentId}/publish`
      );
      const { data } = response.data;
      succ(data);
    } catch (error) {
      console.error("An error occurred:", error);
      err(error);
    }
  };
};

export const updateModalInfo = (
  postData,
  contentId,
  succ = () => { },
  err = () => { }
) => {
  return async (dispatch) => {
    let apiData = {
      // s3_url: "",
      // icon: "",
      // model_name: "",
      name: postData.name,
      // is_approved: postData.isApproved,
      uploaded_file_info: postData.uploaded_file_info,
    };
    try {
      const response = await api.patch(
        `/model/${contentId}`,
        apiData
      );
      const { data } = response.data;
      succ(data);
    } catch (error) {
      console.error("An error occurred:", error);
      err(error);
    }
  };
};

export const getUploadToken = (
  apiData,
  modelId,
  succ = () => { },
  err = () => { },
  file
) => {
  return () => {
    api
      .patch(`/model/upload-model/${modelId}`, apiData)
      .then((response) => {

        const { data } = response.data;
        const formData = new FormData();

        // Append the necessary token fields
        for (const key in data.fields) {
          formData.append(key, data.fields[key]);
        }
        // Append the file
        formData.append('file', file);

        axios
          .post(data.url, formData)
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              succ();
            }
          })
          .catch((error) => {
            err(error);
          });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        err(error);
      });
  };
};

export const dispatchModelsInStore = (data) => ({
  type: UPDATE_MODELS,
  payload: data,
});

export const dispatchModelsAndFavModelsInStore = (data) => ({
  type: UPDATE_MODELS_AND_FAV_MODELS,
  payload: data,
});

export const getUploadedModels = async (dispatch) => {
  try {
    const userId = localStorage.getItem("userId");
    const getModels = await api.get(`/user/${userId}/models`);
    // const favModels = await getFavModels();
    dispatch(
      dispatchModelsInStore(
        getModels?.data?.data?.model || [],
      )
    );
    return getModels.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return error;
  }
};

export const getUploadedModelsWithoutToken = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/marketplace/homepage`);
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return error;
  }
};

export const addComment = async (
  postData,
  id,
  succ = () => { },
  err = () => { }
) => {
  try {
    const response = await api.post(`/webapp/add-comment/${id}`, postData);
    succ(response);
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    err(error);
    return error;
  }
};

export const addFavorite = async (
  postData,
  succ = () => { },
  err = () => { }
) => {
  try {
    const response = await api.post(
      `/model/${postData.id}/like`,
      {}
    );
    succ(response);
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    err(error.response);
    return error;
  }
};

export const removeFavorite = async (
  postData,
  succ = () => { },
  err = () => { }
) => {
  try {
    const response = await api.delete(
      `/model/${postData.id}/like`,
      {}
    );
    succ(response);
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    err(error.response.data);
    return error;
  }
};

export const getFavModels = async () => {
  try {
    const userId = localStorage.getItem("userId");
    const response = await api.get(`/user/${userId}/liked-models`);
    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    return error;
  }
};

export const getSingleModels = async (modelId) => {
  try {
    const response = await api.get(`/model/${modelId}`);
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addFavModel = (data) => ({
  type: ADD_FAV_MODEL,
  payload: data,
});

export const removeFavModel = (data) => ({
  type: REMOVE_FAV_MODEL,
  payload: data,
});


export const removeModel = async (
  modelId,
  succ = () => { },
  err = () => { }
) => {
  try {
    const response = await api.delete(
      `/model/${modelId}`,
      {}
    );
    succ(response);
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    if (error) {
      err(error?.response?.data);
    }
    return error;
  }
};

export const checkUsernameUniqueness = async (username) => {
  try {
    const response = await api.get(`/user/check-username-availability/${username}`);
    return !response?.data?.error;
  } catch (error) {
    console.error('Error checking username uniqueness', error);
    return false;
  }
};