import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Snackbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ViewDialog.css";
import CitationIcon from "../../assets/svg/citation";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonImage from "../../assets/images/person-image.png";
import Globe from "../../assets/images/globe.png";
// import Image3d from "../../assets/images/3DImage.png";
import SvgOneIcon from "../../assets/svg/svgOne";
import SvgTwoIcon from "../../assets/svg/svgTwo";
// import LikeIcon from "../../assets/svg/LikeIcon ";
import SvgThreeIcon from "../../assets/svg/svgThree";
import SvgFourIcon from "../../assets/svg/svgFour";
import SvgFiveIcon from "../../assets/svg/svgFive";
import ViewIcon from "../../assets/svg/eye-view";
import FlagIcon from "../../assets/svg/flag-view";
import ChatIcon from "../../assets/svg/chatIcon";
import ThreeScene from "../ThreeScene";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  addComment,
  addFavModel,
  addFavorite,
  getFileUrl,
  getSingleModels,
  removeFavModel,
  removeFavorite,
} from "../../actions";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { getUserInfo, updateUserInfo } from '../../actions';

import AgreementBusinessIcon from "../../assets/svg/agreementBusiness";
import CornerFrameIcon from "../../assets/svg/cornerFrame";
import BookCopybookIcon from "../../assets/svg/bookCopybook";
import FrameIcon from "../../assets/svg/frameIcon";
import ImageAlbumIcon from "../../assets/svg/imageAlbum";


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, width = 60, height = 60, fontSize = 24) {
  const childrenValue = `${name.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""
    }`;
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width,
      height: height,
      marginRight: "5px",
      fontSize: `${fontSize}px`,
    },
    children: childrenValue,
  };
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1c30",
    color: "#b5b5db",
    boxShadow: theme.shadows[1],
    border: "1px solid #505083",
    fontSize: 11,
  },
}));

const ViewDialog = ({ open, onClose, cardInfo = null }) => {
  const { favModelsState = [] } = useSelector((state) => state.bios);
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.down("sm"));
  const [comment, setComment] = useState("");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [modelInfo, setModelInfo] = useState(null);
  const [modelUserInfo, setModelUserInfo] = useState(null);

  const [replyIndex, setReplyIndex] = useState(null);

  const { data, refetch } = useQuery(
    "singleModelInfo",
    () =>
      getSingleModels(cardInfo?.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setModelInfo(data);
      },
    }
  );


  useEffect(() => {
    if (modelInfo && modelInfo.user_id) {
      getUserInfo().then((userInfoResponse) => {
        setModelUserInfo(userInfoResponse)
      });
    }
  }, [modelInfo]);

  // console.log("modelUserInfo modelUserInfo modelUserInfo modelUserInfo", modelUserInfo);


  const getScientificCollab = () => {
    const key =
      modelInfo?.uploaded_file_info?.modelInfo?.scientificCollaboration || "";
    switch (key) {
      case "none":
        return "No Collaboration";
        break;
      case "community":
        return "Community Driven";
        break;
      case "academic":
        return "Academic Research Collaborations";
        break;
      default:
        return "";
        break;
    }
  };

  const handleAddComment = async () => {
    if (comment.trim()) {
      const postData = {
        comment: comment,
        parent_comment_id: null,
      };
      await addComment(
        postData,
        cardInfo?.id,
        (success) => {
          setSnackbarState({
            open: true,
            message: success.data.msg,
            severity: "success",
          });
          setComment("");
          refetch();
        },
        (error) => {
          setSnackbarState({
            open: true,
            message: "Oops! Something went wrong",
            severity: "error",
          });
        }
      );
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false, message: "", severity: "" });
  };

  const snackbarComponent = () => {
    return (
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseSnackbar}
          severity={snackbarState.severity}
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    );
  };

  useEffect(() => {
    if (modelInfo && !imageUrl) {
      if (modelInfo?.uploaded_file_info?.models.length) {
        getFileUrl(modelInfo?.id)
          .then((response) => {
            setImageUrl(response.data);
          })
          .catch((error) => { });
      }
    }
  }, [imageUrl, modelInfo]);

  const handleDownloadFile = async () => {
    console.log("modelInfo?.uploaded_file_info?.modelInfo", modelInfo)
    console.log("modelInfo?.uploaded_file_info?.cardInfo", cardInfo)
    if (cardInfo && cardInfo.uploaded_file_info?.modelInfo?.download) {
      if (cardInfo?.uploaded_file_info?.models.length) {
        await getFileUrl(cardInfo?.uploaded_file_info.models?.[0].fileName)
          .then((response) => {
            // Create a new anchor element
            const anchor = document.createElement("a");
            anchor.href = response.data;
            anchor.download = cardInfo?.uploaded_file_info.models?.[0].fileName; // Set the file name for download

            // Append the anchor to the body, trigger click, and remove it
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            setSnackbarState({
              open: true,
              message: "File Downloaded Successfully",
              severity: "success",
            });
          })
          .catch((error) => {
            setSnackbarState({
              open: true,
              message: error?.msg ?? "Oops! Something went wrong",
              severity: "error",
            });
          });
      }
    }
  };

  const isFavModel = favModelsState.some(
    (f) => f.id === cardInfo?.id
  );

  const handleFavorite = async () => {
    const postData = {
      id: cardInfo?.id,
    };
    await addFavorite(
      postData,
      (success) => {
        console.log("success", success)
        setSnackbarState({
          open: true,
          message: success.data.msg,
          severity: "success",
        });
        setComment("");
        refetch();
        dispatch(addFavModel(cardInfo));
      },
      (error) => {
        setSnackbarState({
          open: true,
          message: error?.msg ?? "Oops! Something went wrong",
          severity: "error",
        });
      }
    );
  };

  const handleRemoveFavorite = async () => {
    const postData = {
      id: cardInfo?.id,
    };
    await removeFavorite(
      postData,
      (success) => {
        setSnackbarState({
          open: true,
          message: success.data.msg,
          severity: "success",
        });
        setComment("");
        refetch();
        dispatch(removeFavModel(cardInfo));
      },
      (error) => {
        setSnackbarState({
          open: true,
          message: error?.msg ?? "Oops! Something went wrong",
          severity: "error",
        });
      }
    );
  };

  const isObjectFieldsEmpty = (arr) => {
    if (!arr || arr.length === 0) return true;
    // Check if every object in the array meets the condition
    return arr.every((obj) => {
      // Iterate over all keys in the object
      for (let key in obj) {
        // Check if the property is not empty
        if (obj[key] !== "" && obj[key] !== false) {
          return false;
        }
      }
      // If all properties are empty or false, return true for this object
      return true;
    });
  };

  console.log("modelInfo modelInfo cardInfo", modelInfo)
  return (
    <>
      <Dialog
        fullScreen={fullScreenQuery}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        scroll="paper"
        classes={{
          paper: "view-dialog",
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={7} sm={6} xs={12} sx={{}}>
              <div className="threed-container">
                {modelInfo && modelInfo?.uploaded_file_info?.models?.length &&
                  modelInfo?.uploaded_file_info.models?.[0]?.fileFormat ===
                  // eslint-disable-next-line no-mixed-operators
                  ".gltf" || ".glb" ? (
                  <ThreeScene url={imageUrl} />
                ) : (
                  <img src={imageUrl} alt="3d" />
                )}
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "8px 0px" }}>
                  <Typography variant="h5" className="title-view">
                    {modelInfo?.uploaded_file_info?.modelInfo?.modelName || "-"}
                  </Typography>

                  <div className="view-icon-container">
                    <div className="d-flex">
                      <span className="views">
                        <ChatIcon /> {data?.comments?.length ?? 0}
                      </span>
                      <span className="views">
                        <ViewIcon /> {modelInfo?.view_count}
                      </span>
                      <span
                        className={classNames("views", {
                          "liked-class": isFavModel,
                        })}
                        onClick={() =>
                          isFavModel ? handleRemoveFavorite() : handleFavorite()
                        }
                      >
                        <FlagIcon /> {data?.likes ?? 0}
                      </span>
                      {/* {modelInfo?.uploaded_file_info?.field_icon_view?.map(
                        (iconValue) => {
                          return viewIcons[iconValue];
                        }
                      )} */}
                    </div>
                  </div>

                  {/* <div className="view-icon-container">
                    <div className="d-flex">
                      <span className="viewsIcon">
                        <ImageAlbumIcon />
                      </span>
                      <span className="viewsIcon">
                        <CornerFrameIcon />
                      </span>
                      <span
                        className={classNames("viewsIcon", {
                          "liked-class": isFavModel,
                        })}
                      >
                        <FrameIcon />
                      </span>
                      <span className="viewsIcon">
                        <AgreementBusinessIcon />
                      </span>
                      <span className="viewsIcon">
                        <BookCopybookIcon />
                      </span>
                    </div>
                  </div> */}

                  <div className="view-icon-container" style={{ flexDirection: "row" }}>
                    {modelInfo?.uploaded_file_info?.field_icon_view?.map(
                      (iconValue) => {
                        return viewIcons[iconValue];
                      }
                    )}
                  </div>

                </div>
                <Typography variant="h5" className="sub-title-view">
                  {modelInfo?.uploaded_file_info?.modelInfo?.scientificName ||
                    "-"}
                </Typography>
                <div className="comments-count">
                  <Typography variant="h5" className="des-title-view">
                    Description
                  </Typography>
                  {/* <span className="views">
                    <ChatIcon /> {data?.comments?.length ?? 0}
                  </span> */}
                </div>
                <Typography variant="h3" className="des-view">
                  {modelInfo?.uploaded_file_info?.modelInfo?.sceneDescription ||
                    "Not Available (N/A)"}
                </Typography>
                <Typography variant="h3" className="des-view-title">
                  Imaging Reference Data Information
                </Typography>

                <Typography variant="h3" className="des-view">
                  {modelInfo?.uploaded_file_info?.modelInfo?.scientificDataType.join(
                    " , "
                  ) || "Not Available (N/A)"}
                </Typography>

                <Typography variant="h3" className="des-view-title">
                  Citations & other References
                </Typography>
                {modelInfo?.uploaded_file_info?.modelInfo?.scientificDataAndCitation?.scientificData.map(
                  (d) => {
                    return (
                      d.url && (
                        <a href={d.url} target="_blank" rel="noreferrer">
                          <Typography variant="h3" className="des-view">
                            {d.url}
                          </Typography>
                        </a>
                      )
                    );
                  }
                ) || "Not Available (N/A)"}
                <Typography variant="h3" className="des-view-title">
                  UV Mapping
                </Typography>
                <Typography variant="h3" className="des-view">
                  {modelInfo?.uploaded_file_info?.modelInfo?.sceneInfo
                    ?.UvMapValue || "Not Available (N/A)"}
                </Typography>
                {/* <Typography variant="h3" className="des-view-title-2">
                Surface
              </Typography> */}
                <Typography variant="h3" className="des-view-title">
                  Rigging
                </Typography>
                <Typography variant="h3" className="des-view">
                  {modelInfo?.uploaded_file_info?.modelInfo?.riggingInfo
                    ?.description || "Not Available (N/A)"}
                </Typography>
                <Typography variant="h3" className="des-view-title">
                  Animations
                </Typography>
                {modelInfo?.uploaded_file_info?.modelInfo?.animationInfo?.animations?.map(
                  (a) => {
                    return (
                      <Typography variant="h3" className="des-view">
                        Animation Name: {a.name || "Not Available (N/A)"}   <br />
                        FPS: {a.fps || "Not Available (N/A)"} <br />
                        Frames: {a.totalFrames || "Not Available (N/A)"} <br />
                        Duration: {a.duration || "Not Available (N/A)"} <br />
                        Description: {a.description || "Not Available (N/A)"} <br />
                      </Typography>
                    );
                  }
                ) || "Not Available (N/A)"}
              </div>
            </Grid>
            <Grid item lg={4} md={5} sm={6} xs={12} sx={{}}>
              <div className="avatar-cnt" style={{ marginTop: 0 }}>
                <Avatar
                  {...stringAvatar(
                    `${modelUserInfo?.username || "Anonymous"}`,
                    25,
                    25,
                    10
                  )}
                />
                <p className="user_name-publisher">{`${modelUserInfo?.username || "Anonymous"
                  }`}</p>
              </div>
              <div className="publisher-cnt">
                <Typography
                  variant="p"
                  className="content-id"
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                  }}
                >
                  Content ID:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {modelInfo?._id?.$oid || ""}
                  </span>
                </Typography>
                <div className="d-flex flex-column just-center">
                  <CitationIcon />
                  <span className="citation-title">Citations</span>
                </div>
                {modelInfo?.uploaded_file_info?.scientificInfo?.referenceDatabases && <>
                  <Typography variant="h3" className="des-view-title">
                    Reference Information
                  </Typography>
                  <Typography variant="h5" className="des-view">
                    {modelInfo?.uploaded_file_info?.scientificInfo?.referenceDatabases.join(
                      " , "
                    ) || "Not Available (N/A)"}
                  </Typography>
                </>}
                <Typography variant="h3" className="des-view-title">
                  Scientific Collaboration
                </Typography>
                <Typography variant="h3" className="des-view">
                  {getScientificCollab() || "Not Available (N/A)"}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "6px !important" }}
                  className="des-view-title"
                >
                  Biocomplexity
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Primary :{" "}
                  {modelInfo?.uploaded_file_info?.scientificInfo
                    ?.primaryLayer || "Not Available (N/A)"}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Secondary :{" "}
                  {modelInfo?.uploaded_file_info?.scientificInfo?.secondaryLayer.join(
                    " , "
                  ) || "Not Available (N/A)"}
                </Typography>

                <Typography variant="h3" className="des-view-title">
                  Bioprocesses
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {modelInfo?.uploaded_file_info?.scientificInfo?.vocabulary.join(
                    " , "
                  ) || "Not Available (N/A)"}
                </Typography>

                <div className="horizontal-line"></div>

                <Typography variant="h3" className="des-view-title">
                  General Specifications
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Real World Scale :{" "}
                  {modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.worldScale || "Not Available (N/A)"}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Unit :{" "}
                  {modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.unit || "Not Available (N/A)"}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Organized :{" "}
                  {modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.isOrganized
                    ? "Yes"
                    : "No"}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  PBR :{" "}
                  {modelInfo?.uploaded_file_info?.modelInfo?.generalSpec?.pbr
                    ? "Yes"
                    : "No"}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  General Dimension{" "}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Length : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.length || 'Not Available (N/A)'
                    }`}{" "}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Width : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.width || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Height : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.height || 'Not Available (N/A)'
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  Model Geometry
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Vertices : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.vertices || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Edges : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.edges || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Faces : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.faces || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Quad : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.quad || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`Polygons : ${modelInfo?.uploaded_file_info?.modelInfo?.generalSpec
                    ?.polygons || "Not Available (N/A)"
                    }`}
                </Typography>
                <Typography
                  variant="h3"
                  className="des-view-title"
                  sx={{ marginBottom: "8px !important" }}
                >
                  Hardware Requirements
                </Typography>
                <Typography variant="h3" className="des-view-value">
                  {`${modelInfo?.uploaded_file_info?.modelInfo
                    ?.hardwareRequirementDescription || "Not Available (N/A)"
                    }`}{" "}
                </Typography>

                <div
                  className="data-urls"
                  style={{ marginBottom: "10px", marginTop: "15px" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="Licensing-layer">Licensing</label>
                    <select
                      id="Licensing-layer"
                      className="custom-select"
                      style={{ marginTop: "2px", marginBottom: "0px" }}
                      value={
                        modelInfo?.uploaded_file_info?.modelInfo?.licenseInfo
                      }
                      disabled={true}
                    >
                      <option value="" disabled className="custom-option">
                        Select{" "}
                      </option>
                      <option value="editorial" className="custom-option">
                        Editorial
                      </option>
                      <option value="standard" className="custom-option">
                        Standard
                      </option>
                      <option value="commercial" className="custom-option">
                        Commercial
                      </option>
                    </select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="Licensing-layer">File Type</label>
                    <select
                      id="Licensing-layer"
                      className="custom-select"
                      style={{ marginTop: "2px", marginBottom: "0px" }}
                      value={
                        modelInfo?.uploaded_file_info?.models?.[0]?.fileFormat
                      }
                      disabled={true}
                    >
                      <option value="" disabled className="custom-option">
                        Select file type
                      </option>
                      <option value=".obj" className="custom-option">
                        .OBJ (Wavefront)
                      </option>
                      <option value=".glb" className="custom-option">
                        .GLB (Binary)
                      </option>
                      <option value=".gltf" className="custom-option">
                        .GLTF (Binary)
                      </option>
                      <option value=".fbx" className="custom-option">
                        .FBX (Autodesk)
                      </option>
                      <option value=".dae" className="custom-option">
                        .DAE (Collada)
                      </option>
                      <option value=".c4d" className="custom-option">
                        .c4d (Cinema 4D)
                      </option>
                      <option value=".blend" className="custom-option">
                        .BLEND (Blender)
                      </option>
                      <option value=".maya" className="custom-option">
                        .ms/ma (Maya)
                      </option>
                      <option value=".lte" className="custom-option">
                        .lte (Lightwave)
                      </option>
                      <option value=".png" className="custom-option">
                        .png
                      </option>
                      <option value=".zip" className="custom-option">
                        .zip
                      </option>
                      <option value=".7z" className="custom-option">
                        .7z
                      </option>
                      <option value=".rar" className="custom-option">
                        .rar
                      </option>
                    </select>
                  </div>
                  <span
                    className="download-title"
                    style={{ cursor: modelInfo?.uploaded_file_info?.modelInfo?.download ? "pointer" : "auto" }}
                    onClick={handleDownloadFile}
                  >
                    <ArrowDownwardIcon
                      sx={{
                        marginBottom: "-4px",
                        fontSize: "18px",
                      }}
                    />
                    Download
                  </span>
                </div>
              </div>
              {!isObjectFieldsEmpty(
                modelInfo?.uploaded_file_info?.modelInfo?.collaborators || []
              ) ? (
                <>
                  <Typography
                    variant="h3"
                    className="des-view-title-2"
                    sx={{ marginTop: "10px !important" }}
                  >
                    Collaborators
                  </Typography>
                  {modelInfo?.uploaded_file_info?.modelInfo?.collaborators.map(
                    (item) => {
                      return (
                        <>
                          <Typography variant="h3" className="des-view-value">
                            Name: {`${item.name}`}
                          </Typography>
                          <Typography variant="h3" className="des-view-value">
                            Email: {`${item.email}`}
                          </Typography>
                          <Typography variant="h3" className="des-view-value">
                            Role: {`${item.role}`}
                          </Typography>
                          <Typography variant="h3" className="des-view-value">
                            Url: {`${item.url}`}
                          </Typography>
                          <Typography variant="h3" className="des-view-value">
                            Description: {`${item.description}`}
                          </Typography>
                        </>
                      );
                    }
                  )}
                </>
              ) : (
                <></>
              )}

              {!isObjectFieldsEmpty(
                modelInfo?.uploaded_file_info?.scientificInfo
                  .publishedInfo || []
              ) ?
                <>
                  <Typography variant="h3" className="des-view-title-2">
                    Publication
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px", height: "240px", overflow: "auto", padding: "16px" }}>
                    {
                      modelInfo?.uploaded_file_info?.scientificInfo
                        .publishedInfo.map((item, index) => {
                          return <div className="abstract-cnt d-flex flex-column">
                            <a className="abstract-title" href={modelInfo?.uploaded_file_info?.scientificInfo
                              .publishedInfo[index].doi || ''} target="_blank"
                              rel="noreferrer">
                              {modelInfo?.uploaded_file_info?.scientificInfo
                                .publishedInfo[index].name || '-'}
                            </a>
                            <span className="name-details">{modelInfo?.uploaded_file_info?.scientificInfo
                              .publishedInfo[index].title || '-'}</span>
                            {/* <div
                      className="d-flex"
                      style={{ marginTop: "15px", gap: "15px" }}
                    >
                      <div>
                        <img alt="person" src={PersonImage} />
                      </div>
    
                      <div className=" d-flex flex-column">
                        <span className="download-title">Otto Fritz Meyerhof</span>
                        <span className="name-details">Meyerhof Lab</span>
                        <span className="name-details">
                          University of Pennsylvania
                        </span>
                        <span className="publisher-item">Total Grants: ?</span>
                        <span className="publisher-item">
                          Funded By:{" "}
                          <img
                            alt="Globe"
                            src={Globe}
                            style={{ marginBottom: "-6px", marginLeft: "5px" }}
                          />
                        </span>
                        <span className="publisher-item">
                          Partnerships: <span>Rockefeller Foundation</span>
                        </span>
                      </div>
                    </div> */}
                          </div>
                        })
                    }
                  </div>

                </>
                : <></>}
            </Grid>
          </Grid>
          <Typography variant="h3" className="des-view-title-2">
            Comments
          </Typography>
          <div className="comments-box">
            {data?.comments?.map((c, index) => {
              return (
                <>
                  <div
                    className="avatar-cnt"
                    style={{ marginTop: 0, alignItems: 'baseline' }}
                    key={index}
                  >
                    <Avatar {...stringAvatar(c.first_name, 25, 25, 14)} />
                    <div style={{ margin: "15px 0px", flex: 1 }}>
                      <p
                        className="user_name-publisher"
                        style={{ margin: "0px", fontSize: "15px", marginBottom: 10 }}
                      >{c.first_name}</p>
                      <div className="reply-cnt">
                        <span style={{ color: "rgba(255, 255, 255, 0.80)", fontSize: "12px", fontWeight: 300 }}>
                          {c.comment}
                        </span>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <span style={{ color: "white", fontSize: "14px", cursor: 'pointer' }} onClick={() => setReplyIndex(index)}>Reply</span>
                          <span style={{ color: "#3c3c64", fontSize: "12px" }}>
                            {/* <LikeIcon /> {" "} 00 */}
                          </span>
                        </div>
                        {replyIndex === index && <div>
                          <textarea
                            onChange={(e) => { }}
                            type="text"
                            rows="3"
                            cols={4}
                            className="input-custom-textarea-reply"
                            placeholder="Comment Reply"
                          />
                          <div className="post-reply">
                            <Button
                              variant="outlined"
                              className="outlined-button-reply"

                            >
                              Post Reply
                            </Button>
                            <Button
                              variant="outlined"
                              className="outlined-button-cancel"
                              onClick={() => setReplyIndex(null)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="comment-box">
            {/* <label htmlFor="view_descriptions_comments">Comments</label> */}
            <textarea
              type="text"
              id="view_descriptions_comments"
              rows="6"
              cols={4}
              value={comment}
              className="input-custom-textarea-view"
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <Button
            variant="outlined"
            className="outlined-button-comment"
            onClick={handleAddComment}
          >
            Comment on Model
          </Button>

          <div className="d-flex">
            <Button
              variant="outlined"
              className="outlined-button-comment"
              sx={{ margin: "0 auto" }}
            >
              Load More
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {snackbarComponent()}
    </>
  );
};

const viewIcons = {
  1: (
    <LightTooltip
      title="Model based on some image & clearly depicts a structure or process"
      placement="top"
    >
      <span>
        <SvgOneIcon />
      </span>
    </LightTooltip>
  ),
  2: (
    <LightTooltip
      title="Model based on composited Technical Imaging"
      placement="top"
    >
      <span>
        <SvgTwoIcon />
      </span>
    </LightTooltip>
  ),
  3: (
    <LightTooltip title="Citation used to further edit & model" placement="top">
      <span>
        <SvgThreeIcon />
      </span>
    </LightTooltip>
  ),
  4: (
    <LightTooltip
      title="Academic or Scientific Research Partnership"
      placement="top"
    >
      <span>
        <SvgFourIcon />
      </span>
    </LightTooltip>
  ),
  5: (
    <LightTooltip title="Published in Peer-Reviewed Journal" placement="top">
      <span>
        <SvgFiveIcon />
      </span>
    </LightTooltip>
  ),
};

export default ViewDialog;
