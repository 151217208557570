import {
  Avatar,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Snackbar,
  Stack,
  Switch,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./UploadModel.css";
import Button from "@mui/material/Button";
import UploadIcon from "../../assets/svg/upload-cloud-01";
import ImportantIcon from "../../assets/svg/ImportantIcon";
import FileIcon from "../../assets/svg/FileIcon";
import ObjModel from "../../assets/svg/3d_wavefront";
import { useSelector, useDispatch } from "react-redux";
import { uploadModalObject } from "./constants";
import classNames from "classnames";
import { cloneDeep, debounce } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import {
  getSingleModels,
  getUploadToken,
  publishModel,
  updateModalInfo,
  uploadModalInfo,
} from "../../actions";
import ClearIcon from "@mui/icons-material/Clear";
import MuiAlert from "@mui/material/Alert";
import StericIcon from "../../assets/svg/steric";
import { createModelSchema } from "../../Validations/CreateModel";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import Question from "../../assets/svg/Question";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import SvgOneIcon from "../../assets/svg/svgOne";
import SvgTwoIcon from "../../assets/svg/svgTwo";
import SvgThreeIcon from "../../assets/svg/svgThree";
import SvgFourIcon from "../../assets/svg/svgFour";
import SvgFiveIcon from "../../assets/svg/svgFive";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1c30",
    color: "#b5b5db",
    boxShadow: theme.shadows[1],
    border: "1px solid #505083",
    fontSize: 11,
  },
}));

function stringAvatar(name, width = 60, height = 60, fontSize = 24) {
  return {
    sx: {
      bgcolor: "#D9D9D9",
      width: width,
      height: height,
      marginRight: "5px",
      fontSize: `${fontSize}px`,
      color: "black",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      background: "rgb(50 51 69)",
      border: "1px solid rgb(80, 80, 131)",
      color: "white",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const multiSelectStyles = {
  fontSize: "12px",
  fontWeight: "100",
  paddingTop: "5px",
  paddingLeft: "5px",
  paddingRight: "5px",
  paddingBottom: "5px",
  justifyContent: "space-between",
  gap: "15px",
};

const imagingArray = [
  "n/a",
  "NCBI",
  "PDB",
  "GBIF",
  "GPS Area Coordinates",
  "UniProt",
  "JGI",
  "CHEMBL",
  "Cell Image Library",
  "STRING",
  "Human Cell Atlas ID",
  "EMPIAR",
  "Image Data Resource (IDC)",
  "Photogrammetry",
  "Photography",
  "LIDAR",
  "X-RAY",
  "Light-Field Microscopy",
  "Dark-Field Microscopy",
  "Single Cell Microscopy",
  "Ultrasounds",
  "X-Ray crystallography",
  "Confocal microscopy",
  "Inertial Motion Capture",
  "Optical Motion Capture",
  "Other imaging type",
  "Other image datasource (url)",
];
const secondaryLayer = [
  "Biosphere",
  "Biome",
  "Community",
  "Ecosystem",
  "Organism",
  "Organ Syystem",
  "Organ",
  "Tissue",
  "Cell(s)",
  "Organelle(s)",
  "Biomolecules",
  "Molecules",
  "Atoms", 
  "Materials",
  "Subatomic Particles"
];
const licenseType = [
  "CC 0",
  "CC BY",
  "CC BY-SA",
  "CC BY-ND",
  "CC BY-NC",
  "CC BY-NC-SA",
  "CC BY-NC-ND",
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        background: "rgba(217, 217, 217, 0.10)",
        opacity: 1,
      },
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "rgba(0, 0, 255, 1)"
        )}" d="M16.667,5.833L8.333,14.167L3.333,9.167L4.747,7.753L8.333,11.339L15.253,4.419L16.667,5.833Z"/></svg>')`,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    borderRadius: 3,
    border: "1px solid #505083",
    background: "rgba(255, 255, 255, 0.25)",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "rgba(80, 80, 131, 1)"
      )}" d="M16.667,5.833L8.333,14.167L3.333,9.167L4.747,7.753L8.333,11.339L15.253,4.419L16.667,5.833Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    height: "auto",
    borderRadius: 4,
    border: "1px solid #505083",
    background: "rgba(217, 217, 217, 0.10)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UploadDialog = ({ open, onClose, modelId, modelObjectDataProps }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.bios);
  const fullName = `${user?.first_name || "x"} ${user?.last_name || "x"}`;

  const [selectedView, setSelectedView] = useState("3dInfo");
  const [modalObject, setModalObject] = useState(uploadModalObject);
  const [inputVocabulary, setInputVocabulary] = useState("");
  const [isCreateModal, setIsCreateModal] = useState(modelId ? false : true );
  const [isSaving, setIsSaving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [contentId, setContentId] = useState(modelId);
  const prevStateRef = useRef();
  const fileInputRef = useRef(null);

  const handleUpdateGeneralSpec = (key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.generalSpec[key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateScientificData = (key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation[key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateModelInfo = (key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo[key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateModelName = (key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj[key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateScientificInfo = (key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo[key] = value;
    setModalObject(updatedObj);
  };

  const handleChangeView = (value) => {
    setSelectedView(value);
  };

  const theme = useTheme();
  const fullScreenQuery = useMediaQuery(theme.breakpoints.down("lg"));

  const handleSelectChangeImaging = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValue = typeof value === "string" ? value.split(",") : value;
    handleUpdateModelInfo("scientificDataType", selectedValue);
  };

  const handleSelectChangeDatabases = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValue = typeof value === "string" ? value.split(",") : value;
    handleUpdateScientificInfo("referenceDatabases", selectedValue);
  };

  const handleSelectSecondaryLayer = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValue = typeof value === "string" ? value.split(",") : value;

    const secondaryVal = value.map((item) => {
      return { key: item, value: "" };
    });

    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo.secondaryLayer = selectedValue;
    updatedObj.uploaded_file_info.scientificInfo.secondaryValue = secondaryVal;

    // console.log("updatedObj.scientificInfo", updatedObj.scientificInfo)
    // console.log("updatedObj.secondaryValue", updatedObj.secondaryValue)
    setModalObject(updatedObj);
  };

  const handleSelectLicense = (event) => {
    const {
      target: { value },
    } = event;

    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.licenseInfo = value;
    setModalObject(updatedObj);
  };

  const handleAddScientificData = () => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation.scientificData.push({
      url: "",
      dataFiles: [],
    });
    setModalObject(updatedObj);
  };

  const deleteAtIndex = (arr, index) => {
    if (index >= 0 && index < arr.length) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const handleDeleteScientificData = (index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation.scientificData =
      deleteAtIndex(
        updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation.scientificData,
        index
      );
    setModalObject(updatedObj);
  };

  const handleAddPublishedInfo = () => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo.publishedInfo.push({
      name: "",
      title: "",
      doi: "",
    });
    setModalObject(updatedObj);
  };

  const handleDeletePublishedInfo = (index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo.publishedInfo = deleteAtIndex(
      updatedObj.uploaded_file_info.scientificInfo.publishedInfo,
      index
    );
    setModalObject(updatedObj);
  };

  const handleAddAnimations = () => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.animationInfo.animations.push({
      name: "",
      fps: 0,
      totalFrames: 0,
      duration: 0,
      description: "",
      dataFiles: [],
    });
    setModalObject(updatedObj);
  };

  const handleDeleteAnimations = (index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.animationInfo.animations = deleteAtIndex(
      updatedObj.uploaded_file_info.modelInfo.animationInfo.animations,
      index
    );
    setModalObject(updatedObj);
  };

  const handleDeleteCollaborators = (index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.collaborators = deleteAtIndex(
      updatedObj.uploaded_file_info.modelInfo.collaborators,
      index
    );
    setModalObject(updatedObj);
  };

  const handleAddCollaborator = () => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.collaborators.push({
      name: "",
      email: "",
      url: "",
      role: "",
      description: "",
      isCollaboration: false,
    });
    setModalObject(updatedObj);
  };

  const handleAddMaterialTextures = (key) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.sceneInfo[key].push({
      name: "",
      description: "",
      resolution: {
        width: 0,
        height: 0,
      },
      dataFiles: [],
    });
    setModalObject(updatedObj);
  };

  const handleDeleteMaterialTextures = (key, index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.sceneInfo[key] = deleteAtIndex(
      updatedObj.uploaded_file_info.modelInfo.sceneInfo[key],
      index
    );

    setModalObject(updatedObj);
  };

  const handleChangeScientificUrl = (e, index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation.scientificData[index].url =
      e.target.value;
    setModalObject(updatedObj);
  };

  const handleChangeCollaboration = (value, index, key) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.collaborators[index][key] = value;
    setModalObject(updatedObj);
  };

  const handleChangeMaterialTextures = (value, key, index, parentKey) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.sceneInfo[parentKey][index][key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateSecondaryVal = (value, key, index, parentKey) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo[parentKey][index][key] = value;
    setModalObject(updatedObj);
  };

  const handleDeleteSecondaryVal = (parentKey, index ) => {
    const updatedObj = cloneDeep(modalObject);
    const newArr = deleteAtIndex(
      updatedObj.uploaded_file_info.scientificInfo[parentKey],
      index
    );
    updatedObj.uploaded_file_info.scientificInfo.secondaryLayer = newArr.map((item)=> item.key)
    updatedObj.uploaded_file_info.scientificInfo[parentKey] = newArr;

    setModalObject(updatedObj);
  };

  const handleChangeSceneInfo = (value, key) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.sceneInfo[key] = value;
    setModalObject(updatedObj);
  };

  const handleUpdateParentChildComposition = (parentKey, key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo[parentKey][key] = value;
    setModalObject(updatedObj);
  };

  const handleChangeAnimations = (parentKey, index, key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.modelInfo.animationInfo[parentKey][index][key] = value;
    setModalObject(updatedObj);
  };

  const handleAddVocabulary = (value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.scientificInfo.vocabulary.push(value);
    setModalObject(updatedObj);
  };

  const handlePublish = async () => {
    // const isValid = await createModelSchema.isValid(modalObject);
    // if (!isValid) {
    //   setSnackbarState({
    //     open: true,
    //     message:
    //       "Please fill out all mandatory fields marked with a red asterisk (*).",
    //     severity: "error",
    //   });
    //   return;
    // }
    setIsSaving(true);
    const updatedObj = cloneDeep(modalObject);
    updatedObj.isPublished = true;
    if(contentId){
      await dispatch(
        publishModel(
          contentId,
          (succ) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Model Published Successfully",
              severity: "success",
            });
            setTimeout(() => {
              onClose();
            }, 1000);
          },
          ({ response }) => {
            setIsSaving(false);
          }
        )
      );
      // setIsSaving(false);
      // setSnackbarState({
      //   open: true,
      //   message: "Model Updated Successfully",
      //   severity: "success",
      // });
      // setTimeout(() => {
      //   onClose();
      // }, 1000);
    }else{
      dispatch(
        uploadModalInfo(
          updatedObj,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "Model Published Successfully",
              severity: "success",
            });
            setIsSaving(false);
            setModalObject(updatedObj);
            setTimeout(() => {
              onClose();
            }, 1000);
          },
          ({ response }) => {
            setIsSaving(false);
          }
        )
      );
    }
  };

  const getFileObject = (file) => {
    const filename = file.name;
    const dotIndex = filename.indexOf(".");

    let extension = "";
    if (dotIndex !== -1) {
      extension = filename.substring(dotIndex);
    }

    const fileObject = {
      fileName: filename,
      fileFormat: extension,
      size: parseFloat((file.size / 1048576).toFixed(2)),
      renderingSoftwareName: "",
      fileType: "",
      version: "",
    };
    return fileObject;
  };

  const addingFileIntoModelObject = (file) => {
    const fileObject = getFileObject(file);
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.models.push(fileObject);
    setModalObject(updatedObj);
  };

  const handleSelectModel = (event) => {
    const file = event.target.files[0];
    console.log("file", file)
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            addingFileIntoModelObject(file);
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            setIsSaving(false);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };

  const handleAddDocsMaterialTextures = async (event, key, index) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            updatedObj.uploaded_file_info.modelInfo.sceneInfo[key][index].dataFiles.push({
              fileName: file_name,
            });
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };

  const handleAddAnimationsDocs = async (event, index) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            updatedObj.uploaded_file_info.modelInfo.animationInfo.animations[index].dataFiles.push(
              {
                fileName: file_name,
              }
            );
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };

  const handleAddDocsProductPreview = async (event) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            const fileObject = getFileObject(file);
            updatedObj.uploaded_file_info.modelInfo.productPreview.push(fileObject);
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
    if (!event.target.value) {
      // Reset the input field
      fileInputRef.current.value = "";
    }
  };

  const handleAddDocs = async (event, index) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            updatedObj.uploaded_file_info.modelInfo.scientificDataAndCitation.scientificData[
              index
            ].dataFiles.push({
              fileName: file_name,
            });
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };

  const handleSceneAddDocsAiDocs = async (event, key) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            updatedObj.uploaded_file_info.modelInfo[key].dataFiles.push({
              fileName: file_name,
            });
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };
  
  const handleSceneAddDocs = async (event) => {
    const updatedObj = cloneDeep(modalObject);
    const file = event.target.files[0];
    if (file) {
      setIsSaving(true);
      const file_name = file.name;
      dispatch(
        getUploadToken(
          { file_name },
          contentId,
          (succ) => {
            setSnackbarState({
              open: true,
              message: "File Uploaded Successfully!",
              severity: "success",
            });
            updatedObj.uploaded_file_info.modelInfo.sceneInfo.dataFiles.push({
              fileName: file_name,
            });
            setModalObject(updatedObj);
          },
          (err) => {
            setIsSaving(false);
            setSnackbarState({
              open: true,
              message: "Oops! Something went wrong.",
              severity: "error",
            });
          },
          file
        )
      );
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false, message: "", severity: "" });
  };

  const snackbarComponent = () => {
    return (
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseSnackbar}
          severity={snackbarState.severity}
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    );
  };

  const handleAddModelApi = async (updatedData) => {
    setIsSaving(true);
    // new record
    await dispatch(
      uploadModalInfo(
        updatedData,
        (succ) => {
          const { model_id } = succ;
          model_id && setContentId(model_id);
          setIsSaving(false);
          setSnackbarState({
            open: true,
            message: "Model Added Successfully",
            severity: "success",
          });
        },
        ({ response }) => {
          setIsSaving(false);
          setContentId(null);
          setSnackbarState({
            open: true,
            message: "Oops! Something went wrong.",
            severity: "error",
          });
        }
      )
    );
  };

  const handleUpdateModelData = async (updatedData, contentID) => {
    setIsSaving(true);
    await dispatch(
      updateModalInfo(
        updatedData,
        contentID,
        (succ) => {
          setIsSaving(false);
          setSnackbarState({
            open: true,
            message: "Content Updated Successfully",
            severity: "success",
          });
        },
        ({ response }) => {
          setIsSaving(false);
          setSnackbarState({
            open: true,
            message: "Oops! Something went wrong.",
            severity: "error",
          });
        }
      )
    );
  };

  const debouncedAPICallPost = useCallback(
    debounce(handleAddModelApi, 1000),
    []
  );
  const debouncedAPICallPut = useCallback(
    debounce(handleUpdateModelData, 1000),
    []
  );

  useEffect(()=>{
    if (contentId && !isCreateModal) {
      getSingleModels(contentId).then((modelData)=>{
        setModalObject(modelData)
      });
    }
  },[contentId, isCreateModal]);

  useEffect(() => {
    const prevStateJSON = JSON.stringify(prevStateRef.current);

    if (prevStateJSON !== undefined) {
      if (contentId && modalObject) {
        // getSingleModels(contentId).then((modelData)=>{
        //   console.log("response.response modelData ", modelData)
        //   setModalObject(modelData)
        // });

        // if content id is available in state its means user is updating the existing record calling put api
        debouncedAPICallPut(modalObject, contentId);
      } else {
        // new record
        debouncedAPICallPost(modalObject);
      }
    }

    prevStateRef.current = modalObject;

    return () => {
      debouncedAPICallPost.cancel();
      debouncedAPICallPut.cancel();
    };
  }, [modalObject, debouncedAPICallPost, contentId, debouncedAPICallPut]);

  const handleDeleteModel = (index) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info.models = deleteAtIndex(updatedObj.uploaded_file_info.models, index);
    setModalObject(updatedObj);
  };

  const handleChangeModel = (parentKey, index, key, value) => {
    const updatedObj = cloneDeep(modalObject);
    updatedObj.uploaded_file_info[parentKey][index][key] = value;
    setModalObject(updatedObj);
  };

  const handleSelectFieldIcon = (key) => {
    const updatedObj = cloneDeep(modalObject);
    const isExist = updatedObj.uploaded_file_info.field_icon_view.find((item) => item === key);
    if (isExist) {
      updatedObj.uploaded_file_info.field_icon_view = updatedObj.uploaded_file_info.field_icon_view.filter(
        (item) => item !== key
      );
    } else {
      updatedObj.uploaded_file_info.field_icon_view.push(key);
    }
    setModalObject(updatedObj);
  };

  // console.log("modalObject modalObject modalObject", modalObject)

  return (
    <>
      <Dialog
        fullScreen={fullScreenQuery}
        // fullScreen
        maxWidth={"xl"}
        open={open}
        onClose={onClose}
        classes={{
          paper: "upload-dialog",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="btn-uploads">
            {/* <Button
              variant="outlined"
              className="outlined-button-3d"
              onClick={() => handleChangeView("3dInfo")}
            >
              3D File Information
            </Button> */}
            {/* <Button
              variant="outlined"
              className="outlined-button-3d"
              onClick={() => handleChangeView("sciInfo")}
            >
              Scientific Information
            </Button> */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                margin: 0,
                color: "white",
              }}
            >
              <CloseIcon sx={{ color: "#505083" }} />
            </IconButton>
          </div>

          <div style={{ display: "flex", gap: "30px" }}>
            {isSaving && (
              <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
                <span className="save-title">Saving Form...</span>
                <CircularProgress size={20} thickness={4} />
              </div>
            )}

            <Button
              onClick={handlePublish}
              variant="contained"
              className={classNames("contained-button-publish", {
                "contained-button-published": modalObject.isPublished,
              })}
            >
              {modalObject.isPublished ? (
                <>
                  {" "}
                  Published <TaskAltOutlinedIcon
                    sx={{ marginLeft: "4px" }}
                  />{" "}
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
        </Toolbar>
        {selectedView === "3dInfo" && (
          <Fragment>
            <div className="upload-content-container">
              {/* Your dialog content here */}
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px" }}
                >
                  <Typography variant="p" className="content-id">
                    Content ID:{" "}
                    <span style={{ fontWeight: 500 }}>{contentId || ""}</span>
                  </Typography>
                  <div className="ios-switch-cnt">
                    <IOSSwitch
                      checked={approved}
                      onChange={(e) => {
                        // Switch disabled so user cannot trigger this function but can observe state
                        //setApproved((prevState) => !prevState);
                      }}
                      disabled={true}
                    />
                    <div className="ios-switch-info">
                      <span className="approved-title">
                        {approved ? "Approved" : "Requested"}
                      </span>
                      <span className="approved-title">00.00.00</span>
                      <span className="approved-title">00:00</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="p" className="content-owner">
                      Content Owner
                    </Typography>
                    <div className="fields-icons">
                      {Array(5)
                        .fill("")
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames("disable-icon", {
                                "active-icon": 
                                  modalObject?.uploaded_file_info?.field_icon_view?.includes(index + 1),
                              })}
                              onClick={() =>
                                {
                                  // if(!contentId){
                                    // handleSelectFieldIcon(index + 1)
                                  // }
                                }
                              }
                            >
                              {viewIcons[index + 1]}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="avatar-cnt" style={{ marginTop: 0 }}>
                    <Avatar {...stringAvatar(fullName, 15, 15, 6)} />
                    <p className="user_name-upload">{fullName}</p>
                  </div>
                  <div style={{ display: "flex", gap: "60px" }}>
                    <span className="serial-no">1.</span>
                    <div style={{ display: "flex", gap: "12px" }}>
                      <div className="showcase-upload">
                        <label htmlFor="modelUpload">
                          <div className="showcase-cnt-upload">
                            <UploadIcon />
                            <Typography
                              variant="h3"
                              className="upload-content-modal"
                            >
                              Upload Model File(s) here
                            </Typography>
                            <input
                              type="file"
                              id="modelUpload"
                              accept=".fbx,.obj,.c4d,.lwo,.lw,.mb,.ma,.blend,.3ds,.max,.png,.gif,.zip,.rar,.7z,.gltf,.jpeg,.jpg,.glb"
                              onChange={handleSelectModel}
                            />
                          </div>
                        </label>
                      </div>
                      {/* <StericIcon /> */}
                    </div>
                  </div>
                  <Typography variant="p" className="upload-content-detail">
                    Embedded GLTF Files are preferred and are the only file type that will currently reder inour viewer, but we support hosting FBX, OBJ, Cinema 4D, Lightwave, Maya,
                    and more for storage, transfer, & reference purposes.
                  </Typography>
                  <Typography variant="p" className="upload-content-detail">
                    You may also upload .png, gif, ZIP, RAR, or
                    7z files independently for textures, materials, effects, etc..
                  </Typography>
                  {modalObject?.uploaded_file_info?.models?.map((item, index) => {
                    return (
                      <div className="file-uploader-cnt" key={index + "models"}>
                        <Typography
                          variant="p"
                          className="content-id"
                          sx={{
                            position: "absolute",
                            right: "10px",
                            top: "8px",
                          }}
                        >
                          Model ID <span style={{ fontWeight: 500 }}>001M</span>
                        </Typography>
                        <span
                          className="delete-txt"
                          onClick={() => handleDeleteModel(index)}
                        >
                          Delete
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <ObjModel />
                          <Typography variant="h5" className="image-name">
                            {item.fileName}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <select
                            id="file-format"
                            value={item.fileFormat}
                            onChange={(e) =>
                              handleChangeModel(
                                "models",
                                index,
                                "fileFormat",
                                e.target.value
                              )
                            }
                            className="custom-select"
                            disabled={item.fileFormat !== ""}
                          >
                            <option value="" disabled className="custom-option">
                              Select file type
                            </option>
                            <option value=".obj" className="custom-option">
                              .OBJ (Wavefront)
                            </option>
                            <option value=".glb" className="custom-option">
                              .GLB (Binary)
                            </option>
                            <option value=".gltf" className="custom-option">
                              .GLTF (Binary)
                            </option>
                            <option value=".fbx" className="custom-option">
                              .FBX (Autodesk)
                            </option>
                            <option value=".dae" className="custom-option">
                              .DAE (Collada)
                            </option>
                            <option value=".c4d" className="custom-option">
                              .c4d (Cinema 4D)
                            </option>
                            <option value=".blend" className="custom-option">
                              .BLEND (Blender)
                            </option>
                            <option value=".maya" className="custom-option">
                              .ms/ma (Maya)
                            </option>
                            <option value=".lte" className="custom-option">
                              .lte (Lightwave)
                            </option>
                            <option value=".png" className="custom-option">
                              .png
                            </option>
                            <option value=".gltf" className="custom-option">
                              .gltf
                            </option>
                            <option value=".jpg" className="custom-option">
                              .jpg
                            </option>
                            <option value=".jpeg" className="custom-option">
                              .jpeg
                            </option>
                            <option value=".zip" className="custom-option">
                              .zip
                            </option>
                            <option value=".7z" className="custom-option">
                              .7z
                            </option>
                            <option value=".rar" className="custom-option">
                              .rar
                            </option>
                          </select>
                          <select
                            id="file-type"
                            value={item.fileType}
                            onChange={(e) =>
                              handleChangeModel(
                                "models",
                                index,
                                "fileType",
                                e.target.value
                              )
                            }
                            className="custom-select"
                          >
                            <option value="" disabled className="custom-option">
                              Select type
                            </option>
                            <option value="model" className="custom-option">
                              Model
                            </option>
                            <option value="texture" className="custom-option">
                              Texture
                            </option>
                            <option value="material" className="custom-option">
                              Material
                            </option>
                            <option value="animation" className="custom-option">
                              Animation
                            </option>
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h5" className="image-name">
                            size{" "}
                            <span style={{ fontSize: "14px" }}>
                              {`${item.size}MB`}
                            </span>
                          </Typography>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="render-software">
                              Rendering Software
                            </label>
                            <input
                              value={item.renderingSoftwareName}
                              type="text"
                              id="render-software"
                              className="input-custom-render"
                              placeholder=""
                              onChange={(e) =>
                                handleChangeModel(
                                  "models",
                                  index,
                                  "renderingSoftwareName",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="version-software">Version</label>
                            <input
                              value={item.version}
                              type="text"
                              id="version-software"
                              className="input-custom-version"
                              placeholder=""
                              onChange={(e) =>
                                handleChangeModel(
                                  "models",
                                  index,
                                  "version",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  ;<div className="horizontal-line"></div>
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    <span className="serial-no">1.</span>
                    <div className="general-spec-cnt">
                      <span className="general-title">
                        General Specifications 
                      </span>
                      <span className="scene">Scene</span>
                      <div className="general-left">
                        <div className="general-param">
                          <span className="scene" style={{ fontSize: "10px" }}>
                            Real-World Scale{" "}
                            <input
                              className="custom-small-input"
                              type="number"
                              value={
                                modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.worldScale
                              }
                              onChange={(e) =>
                                handleUpdateGeneralSpec(
                                  "worldScale",
                                  e.target.value
                                )
                              }
                            />
                          </span>
                          <span className="scene" style={{ fontSize: "10px" }}>
                            Organized{" "}
                            <span
                              className="col-item"
                              onClick={() =>
                                handleUpdateGeneralSpec(
                                  "isOrganized",
                                  !modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.isOrganized
                                )
                              }
                            >
                              {modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.isOrganized
                                ? "Yes"
                                : "No"}
                            </span>
                          </span>
                          <span className="scene" style={{ margin: "7px 0px" }}>
                            PBR{" "}
                            <span
                              className="col-item"
                              onClick={() =>
                                handleUpdateGeneralSpec(
                                  "pbr",
                                  !modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.pbr
                                )
                              }
                            >
                              {modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.pbr
                                ? "Yes"
                                : "No"}
                            </span>
                          </span>
                        </div>
                        <div className="general-param">
                          <span className="scene" style={{ fontSize: "10px" }}>
                            Units{" "}
                            <span
                              className={classNames("unit-style", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.unit ===
                                  "m",
                              })}
                              onClick={() =>
                                handleUpdateGeneralSpec("unit", "m")
                              }
                            >
                              m
                            </span>
                            <span
                              className={classNames("unit-style", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.unit ===
                                  "cm",
                              })}
                              onClick={() =>
                                handleUpdateGeneralSpec("unit", "cm")
                              }
                            >
                              cm
                            </span>
                            <span
                              className={classNames("unit-style", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.unit ===
                                  "mm",
                              })}
                              onClick={() =>
                                handleUpdateGeneralSpec("unit", "mm")
                              }
                            >
                              mm
                            </span>
                          </span>
                          <span className="scene" style={{ margin: "7px 0px" }}>
                            General Dimensions
                          </span>
                          <span className="scene">
                            <span
                              className="unit-style"
                              style={{ background: "none" }}
                            >
                              L{" "}
                              <input
                                className="custom-small-input"
                                type="number"
                                value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.length}
                                onChange={(e) =>
                                  handleUpdateGeneralSpec(
                                    "length",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                            <span
                              className="unit-style"
                              style={{ background: "none" }}
                            >
                              W{" "}
                              <input
                                className="custom-small-input"
                                type="number"
                                value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.width}
                                onChange={(e) =>
                                  handleUpdateGeneralSpec(
                                    "width",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                            <span
                              className="unit-style"
                              style={{ background: "none" }}
                            >
                              H{" "}
                              <input
                                className="custom-small-input"
                                type="number"
                                value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.height}
                                onChange={(e) =>
                                  handleUpdateGeneralSpec(
                                    "height",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <span className="scene">Model Geometry</span>
                      <div className="d-flex">
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Vertices{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.vertices}
                            onChange={(e) =>
                              handleUpdateGeneralSpec(
                                "vertices",
                                e.target.value
                              )
                            }
                          />
                        </span>
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Edges{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.edges}
                            onChange={(e) =>
                              handleUpdateGeneralSpec("edges", e.target.value)
                            }
                          />
                        </span>
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Faces{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.faces}
                            onChange={(e) =>
                              handleUpdateGeneralSpec("faces", e.target.value)
                            }
                          />
                        </span>
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Triangles{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.triangles}
                            onChange={(e) =>
                              handleUpdateGeneralSpec(
                                "triangles",
                                e.target.value
                              )
                            }
                          />
                        </span>
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Quad{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.quad}
                            onChange={(e) =>
                              handleUpdateGeneralSpec("quad", e.target.value)
                            }
                          />
                        </span>
                        <span
                          className="scene"
                          style={{ fontSize: "10px", marginBottom: "5px" }}
                        >
                          Polygons{" "}
                          <input
                            className="custom-small-input"
                            type="number"
                            value={modalObject?.uploaded_file_info?.modelInfo?.generalSpec?.polygons}
                            onChange={(e) =>
                              handleUpdateGeneralSpec(
                                "polygons",
                                e.target.value
                              )
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    <span className="serial-no">2.</span>
                    <div className="d-flex flex-column gap5">
                      <span className="general-title">
                        Scientific Reference Data Type(s)
                      </span>
                      <span className="scene">
                        Select type of imaging data used to model scene
                      </span>
                      <Select
                        multiple
                        value={modalObject?.uploaded_file_info?.modelInfo?.scientificDataType}
                        onChange={handleSelectChangeImaging}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        className="custom-multi-select"
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select</span>;
                          }
                          return selected.join(", ");
                        }}
                      >
                        {imagingArray.map((item, index) => (
                          <MenuItem
                            key={index + "imagingArray"}
                            value={item}
                            sx={multiSelectStyles}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <span className="serial-no">3.</span>
                    <div className="d-flex flex-column gap5">
                      <span className="general-title">
                        Scientific Reference Data & Citations 
                      </span>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          checked={
                            modalObject?.uploaded_file_info?.modelInfo?.scientificDataAndCitation
                              .isVisible
                          }
                          inputProps={{ "aria-label": "ant design" }}
                          onChange={(e) =>
                            handleUpdateScientificData(
                              "isVisible",
                              !modalObject?.uploaded_file_info?.modelInfo?.scientificDataAndCitation
                                .isVisible
                            )
                          }
                        />
                        <Typography className="switch-title">
                          Visible to Public
                        </Typography>
                      </Stack>
                      {modalObject?.uploaded_file_info?.modelInfo?.scientificDataAndCitation?.scientificData?.map(
                        (item, indexScientificData) => {
                          return (
                            <div
                              className="data-urls"
                              key={
                                indexScientificData +
                                "scientificDataAndCitation"
                              }
                            >
                              {modalObject?.uploaded_file_info?.modelInfo?.scientificDataAndCitation
                                ?.scientificData?.length > 1 && (
                                  <ClearIcon
                                    className="custom-clear-icon"
                                    onClick={() =>
                                      handleDeleteScientificData(
                                        indexScientificData
                                      )
                                    }
                                  />
                                )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <label htmlFor="data-url">Data Url</label>
                                <input
                                  type="text"
                                  value={item.url}
                                  id="data-url"
                                  className="input-custom-render"
                                  placeholder=""
                                  onChange={(e) =>
                                    handleChangeScientificUrl(
                                      e,
                                      indexScientificData
                                    )
                                  }
                                />
                              </div>
                              <span className="or">or</span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <label
                                  htmlFor={`data-files-${indexScientificData}`}
                                >
                                  Data Files
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span className="upload-docs">
                                      Upload docs
                                      <UploadIcon
                                        sx={{
                                          fontSize: "14px",
                                          margin: "0px 4px -2px 4px",
                                          color: "#505083",
                                        }}
                                      />
                                    </span>
                                    <input
                                      type="file"
                                      className="data-files"
                                      id={`data-files-${indexScientificData}`}
                                      accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                      onChange={(e) =>
                                        handleAddDocs(e, indexScientificData)
                                      }
                                    />
                                  </div>
                                </label>
                              </div>
                              {item.dataFiles.map((item, indexChild) => (
                                <span
                                  className="file-names"
                                  key={indexChild + "dataFilesDox"}
                                >
                                  {item.fileName}
                                </span>
                              ))}
                            </div>
                          );
                        }
                      )}
                      <AddIcon
                        className="custom-add-icon"
                        onClick={handleAddScientificData}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ marginTop: "100px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <span className="serial-no">4.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Model Name 
                          </span>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="model-name">
                              Common name of 3D model, scene, or animation
                            </label>
                            <input
                              value={modalObject?.name}
                              onChange={(e) =>
                                handleUpdateModelName(
                                  "name",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="model-name"
                              className="input-custom-render"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "6px" }}>
                        <span className="serial-no">5.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Scientific Name 
                          </span>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="scientific-name">
                              Input Binomial Nomenclature, Scientific Name, or
                              Official Name
                            </label>
                            <input
                              value={modalObject?.uploaded_file_info?.modelInfo?.scientificName}
                              onChange={(e) =>
                                handleUpdateModelInfo(
                                  "scientificName",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="scientific-name"
                              className="input-custom-render"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ marginTop: "88px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">6.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Scientific Collaboration
                          </span>
                          <span
                            className="scene"
                            style={{ lineHeight: "23px", fontSize: "9px" }}
                          >
                            <span
                              className={classNames("col-item", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo
                                    ?.scientificCollaboration === "none",
                              })}
                              onClick={() =>
                                handleUpdateModelInfo(
                                  "scientificCollaboration",
                                  "none"
                                )
                              }
                            >
                              No Collaboration
                            </span>
                            <span
                              className={classNames("sci-col-item", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo
                                    ?.scientificCollaboration === "community",
                              })}
                              onClick={() =>
                                handleUpdateModelInfo(
                                  "scientificCollaboration",
                                  "community"
                                )
                              }
                            >
                              Community Driven
                            </span>
                            <span
                              className={classNames("sci-col-item", {
                                "unit-style-selected":
                                  modalObject?.uploaded_file_info?.modelInfo
                                    ?.scientificCollaboration === "academic",
                              })}
                              onClick={() =>
                                handleUpdateModelInfo(
                                  "scientificCollaboration",
                                  "academic"
                                )
                              }
                            >
                              Academic Research Collaborations
                            </span>
                          </span>
                          <span className="scene">
                            Models with research, lab, or organization
                            collaboration must upload docs detailing purpose,
                            roles, methodology, & references
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <span className="serial-no">7.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">Collaborator(s)</span>
                          {modalObject?.uploaded_file_info?.modelInfo?.collaborators?.map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  key={index + "collaborators"}
                                >
                                  {modalObject?.uploaded_file_info?.modelInfo?.collaborators?.length >
                                    1 && (
                                      <ClearIcon
                                        className="custom-clear-icon"
                                        onClick={() =>
                                          handleDeleteCollaborators(index)
                                        }
                                      />
                                    )}
                                  <div className="data-urls">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="collaborator-name">
                                        Name (person/org/username)
                                      </label>
                                      <input
                                        value={item.name}
                                        onChange={(e) =>
                                          handleChangeCollaboration(
                                            e.target.value,
                                            index,
                                            "name"
                                          )
                                        }
                                        type="text"
                                        id="collaborator-name"
                                        className="input-custom-render"
                                        placeholder=""
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="collaborator-email">
                                        Email (institute pref.)
                                      </label>
                                      <input
                                        value={item.email}
                                        onChange={(e) =>
                                          handleChangeCollaboration(
                                            e.target.value,
                                            index,
                                            "email"
                                          )
                                        }
                                        type="text"
                                        id="collaborator-email"
                                        className="input-custom-render"
                                        placeholder=""
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="collaborator-social-url">
                                        Social URL
                                      </label>
                                      <input
                                        value={item.url}
                                        onChange={(e) =>
                                          handleChangeCollaboration(
                                            e.target.value,
                                            index,
                                            "url"
                                          )
                                        }
                                        type="text"
                                        id="collaborator-social-url"
                                        className="input-custom-render"
                                        placeholder=""
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="collaborator-role">
                                        Role
                                      </label>
                                      <input
                                        value={item.role}
                                        onChange={(e) =>
                                          handleChangeCollaboration(
                                            e.target.value,
                                            index,
                                            "role"
                                          )
                                        }
                                        type="text"
                                        id="collaborator-role"
                                        className="input-custom-render"
                                        placeholder=""
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="collaborator-desc">
                                        Collaboration Description (who, why,
                                        what, how)
                                      </label>
                                      <input
                                        value={item.description}
                                        onChange={(e) =>
                                          handleChangeCollaboration(
                                            e.target.value,
                                            index,
                                            "description"
                                          )
                                        }
                                        type="text"
                                        id="collaborator-desc"
                                        className="input-custom-render"
                                        placeholder=""
                                      />
                                    </div>
                                    <span
                                      className="scene"
                                      style={{
                                        textAlign: "center",
                                        flex: "1 1 auto",
                                      }}
                                    >
                                      Sci Collab <br />
                                      <span
                                        className="col-item"
                                        onClick={() =>
                                          handleChangeCollaboration(
                                            !item.isCollaboration,
                                            index,
                                            "isCollaboration"
                                          )
                                        }
                                      >
                                        {item.isCollaboration ? "Yes" : "No"}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <AddIcon
                            className="custom-add-icon"
                            onClick={handleAddCollaborator}
                          />
                        </div>
                      </div>
                    </Grid>
                    <div
                      className="horizontal-line"
                      style={{ width: "100%" }}
                    ></div>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div style={{ display: "flex", gap: "6px" }}>
                        <span className="serial-no"></span>
                        <div className="d-flex flex-column gap5">
                          <Typography variant="p" className="content-owner">
                            Scene Info
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">8a.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Scene Organization 
                          </span>
                          <span className="scene">
                            Scene Organization Methodology & Description
                          </span>
                          <div className="d-flex">
                            <textarea
                              value={
                                modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.description
                              }
                              onChange={(e) =>
                                handleChangeSceneInfo(
                                  e.target.value,
                                  "description"
                                )
                              }
                              type="text"
                              id="org_descriptions"
                              rows="3"
                              cols={4}
                              className="input-custom-textarea-upload"
                            />
                            <div>
                              <span className="or">and/or</span>
                              <label htmlFor="scene-info-docs">
                                <span className="upload-docs">
                                  Upload Docs
                                  <UploadIcon
                                    sx={{
                                      fontSize: "14px",
                                      margin: "0px 4px -2px 4px",
                                      color: "#505083",
                                    }}
                                  />
                                </span>
                                <input
                                  type="file"
                                  id="scene-info-docs"
                                  accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                  onChange={handleSceneAddDocs}
                                />
                              </label>
                            </div>
                          </div>
                          {modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.dataFiles?.map(
                            (item, indexChild) => (
                              <span
                                className="file-names"
                                key={indexChild + "dataFilesSceneInfo"}
                              >
                                {item.fileName}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">8b.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">UV Mapping</span>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AntSwitch
                              checked={
                                modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.isUvMapping
                              }
                              onChange={() =>
                                handleChangeSceneInfo(
                                  !modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.isUvMapping,
                                  "isUvMapping"
                                )
                              }
                              inputProps={{ "aria-label": "ant design" }}
                            />
                            <Typography className="switch-title">
                              Has UV Mapping
                            </Typography>
                          </Stack>
                          <select
                            id="uv-format"
                            className="custom-select"
                            value={modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.UvMapValue}
                            onChange={(e) =>
                              handleChangeSceneInfo(
                                e.target.value,
                                "UvMapValue"
                              )
                            }
                          >
                            <option value="" disabled className="custom-option">
                              Select type
                            </option>
                            <option value="wrapped" className="custom-option">
                              Wrapped
                            </option>
                            <option value="unwrapped" className="custom-option">
                              Unwrapped
                            </option>
                            <option
                              value="nonOverlapping"
                              className="custom-option"
                            >
                              Unwrapped non overlapping
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">8c.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Material(s)
                          </span>
                          {modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.materials?.map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  key={index + "sceneInfo"}
                                >
                                  {modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.materials
                                    ?.length > 1 && (
                                      <ClearIcon
                                        className="custom-clear-icon"
                                        onClick={() =>
                                          handleDeleteMaterialTextures(
                                            "materials",
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  <div
                                    className="data-urls"
                                    style={{
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <label htmlFor="material-name">
                                          Material Name
                                        </label>
                                        <input
                                          value={item.name}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              e.target.value,
                                              "name",
                                              index,
                                              "materials"
                                            )
                                          }
                                          type="text"
                                          id="material-name"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <label htmlFor="material-des">
                                          Description
                                        </label>
                                        <input
                                          value={item.description}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              e.target.value,
                                              "description",
                                              index,
                                              "materials"
                                            )
                                          }
                                          type="text"
                                          id="material-des"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label htmlFor="material-res">
                                        Resolution
                                      </label>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "3px",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <input
                                          value={item.resolution.width}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              {
                                                ...item.resolution,
                                                width: e.target.value,
                                              },
                                              "resolution",
                                              index,
                                              "materials"
                                            )
                                          }
                                          type="number"
                                          id="material-res"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                        <span className="or">x</span>
                                        <input
                                          value={item.resolution.height}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              {
                                                ...item.resolution,
                                                height: e.target.value,
                                              },
                                              "resolution",
                                              index,
                                              "materials"
                                            )
                                          }
                                          type="number"
                                          id="material-res2"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                        <label
                                          htmlFor={`materials-docs-${index}`}
                                        >
                                          <span className="upload-docs">
                                            Upload Docs
                                            <UploadIcon
                                              sx={{
                                                fontSize: "14px",
                                                margin: "0px 4px -2px 4px",
                                                color: "#505083",
                                              }}
                                            />
                                          </span>
                                          <input
                                            type="file"
                                            className="materials-docs"
                                            id={`materials-docs-${index}`}
                                            accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                            onChange={(e) =>
                                              handleAddDocsMaterialTextures(
                                                e,
                                                "materials",
                                                index
                                              )
                                            }
                                          />
                                        </label>
                                        {item.dataFiles.map(
                                          (item, indexChild) => (
                                            <span
                                              className="file-names"
                                              key={indexChild + "materials"}
                                            >
                                              {item.fileName}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <AddIcon
                            className="custom-add-icon"
                            onClick={() =>
                              handleAddMaterialTextures("materials")
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">8d.</span>
                        <div className="d-flex flex-column gap5">
                          <span className="general-title">
                            Texture(s) 
                          </span>
                          {modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.textures?.map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  key={index + "textures"}
                                >
                                  {modalObject?.uploaded_file_info?.modelInfo?.sceneInfo?.textures
                                    ?.length > 1 && (
                                      <ClearIcon
                                        className="custom-clear-icon"
                                        onClick={() =>
                                          handleDeleteMaterialTextures(
                                            "textures",
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  <div
                                    className="data-urls"
                                    style={{
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <label htmlFor="texture-name">
                                          Texture Name
                                        </label>
                                        <input
                                          value={item.name}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              e.target.value,
                                              "name",
                                              index,
                                              "textures"
                                            )
                                          }
                                          type="text"
                                          id="texture-name"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <label htmlFor="texture-des">
                                          Description
                                        </label>
                                        <input
                                          value={item.description}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              e.target.value,
                                              "description",
                                              index,
                                              "textures"
                                            )
                                          }
                                          type="text"
                                          id="texture-des"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <label
                                        htmlFor="texture-res"
                                        type="number"
                                      >
                                        Resolution
                                      </label>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "3px",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <input
                                          value={item.resolution.width}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              {
                                                ...item.resolution,
                                                width: e.target.value,
                                              },
                                              "resolution",
                                              index,
                                              "textures"
                                            )
                                          }
                                          type="number"
                                          id="texture-res"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                        <span className="or">x</span>
                                        <input
                                          value={item.resolution.height}
                                          onChange={(e) =>
                                            handleChangeMaterialTextures(
                                              {
                                                ...item.resolution,
                                                height: e.target.value,
                                              },
                                              "resolution",
                                              index,
                                              "textures"
                                            )
                                          }
                                          type="number"
                                          id="texture-res2"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                        <label
                                          htmlFor={`data-files-texture-${index}`}
                                        >
                                          <span className="upload-docs">
                                            Upload Docs
                                            <UploadIcon
                                              sx={{
                                                fontSize: "14px",
                                                margin: "0px 4px -2px 4px",
                                                color: "#505083",
                                              }}
                                            />
                                          </span>
                                          <input
                                            type="file"
                                            className="materials-docs"
                                            id={`data-files-texture-${index}`}
                                            accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                            onChange={(e) =>
                                              handleAddDocsMaterialTextures(
                                                e,
                                                "textures",
                                                index
                                              )
                                            }
                                          />
                                        </label>
                                        {item.dataFiles.map(
                                          (item, indexChild) => (
                                            <span
                                              className="file-names"
                                              key={indexChild + "textures"}
                                            >
                                              {item.fileName}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}

                          <AddIcon
                            className="custom-add-icon"
                            onClick={() =>
                              handleAddMaterialTextures("textures")
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">11.</span>
                        <div
                          className="d-flex flex-column gap5"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span className="general-title">
                            Hardware Requirements 
                          </span>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="hardware-des">Description</label>
                            <textarea
                              value={
                                modalObject?.uploaded_file_info?.modelInfo?.hardwareRequirementDescription
                              }
                              onChange={(e) =>
                                handleUpdateModelInfo(
                                  "hardwareRequirementDescription",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="hardware-des"
                              rows="3"
                              cols={4}
                              className="input-custom-textarea-upload"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">9.</span>
                        <div
                          className="d-flex flex-column gap5"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span className="general-title">
                            AI Used 
                          </span>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AntSwitch
                              checked={
                                modalObject.uploaded_file_info.modelInfo.aIUsedInfo.isAiUsed
                              }
                              onChange={() =>
                                handleUpdateParentChildComposition(
                                  "aIUsedInfo",
                                  "isAiUsed",
                                  !modalObject.uploaded_file_info.modelInfo.aIUsedInfo.isAiUsed
                                )
                              }
                              inputProps={{ "aria-label": "ant design" }}
                            />
                            <Typography className="switch-title">
                              AI not used
                            </Typography>
                          </Stack>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "15px",
                            }}
                          >
                            <select
                              id="ai-format"
                              className="custom-select"
                              value={
                                modalObject.uploaded_file_info.modelInfo.aIUsedInfo.aiUsedOption
                              }
                              onChange={(e) =>
                                handleUpdateParentChildComposition(
                                  "aIUsedInfo",
                                  "aiUsedOption",
                                  e.target.value
                                )
                              }
                            >
                              <option
                                value=""
                                disabled
                                className="custom-option"
                              >
                                Select Use
                              </option>
                              <option
                                value="assisted"
                                className="custom-option"
                              >
                                Assisted
                              </option>
                              <option value="partial" className="custom-option">
                                Partially Generated
                              </option>
                              <option value="full" className="custom-option">
                                Fully Generated
                              </option>
                            </select>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "-5px",
                              }}
                            >
                              <label htmlFor="model-software-name">
                                Model/Software Name
                              </label>
                              <input
                                value={modalObject.uploaded_file_info.modelInfo.aIUsedInfo.name}
                                onChange={(e) =>
                                  handleUpdateParentChildComposition(
                                    "aIUsedInfo",
                                    "name",
                                    e.target.value
                                  )
                                }
                                type="text"
                                id="model-software-name"
                                className="input-custom-render"
                                placeholder=""
                                style={{ height: "27px" }}
                              />
                            </div>
                          </div>
                          <span className="scene">
                            Description & Methodology
                          </span>
                          <div className="d-flex">
                            <textarea
                              value={
                                modalObject.uploaded_file_info.modelInfo.aIUsedInfo.description
                              }
                              onChange={(e) =>
                                handleUpdateParentChildComposition(
                                  "aIUsedInfo",
                                  "description",
                                  e.target.value
                                )
                              }
                              type="text"
                              id='"model-software-descriptions'
                              rows="3"
                              cols={4}
                              className="input-custom-textarea-upload"
                            />
                            <div>
                              <span className="or">and/or</span>
                              <label htmlFor="aIUsedInfoDocs">
                                <span className="upload-docs">
                                  Upload Docs
                                  <UploadIcon
                                    sx={{
                                      fontSize: "14px",
                                      margin: "0px 4px -2px 4px",
                                      color: "#505083",
                                    }}
                                  />
                                </span>
                                <input
                                  type="file"
                                  id="aIUsedInfoDocs"
                                  accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                  onChange={(e) =>
                                    handleSceneAddDocsAiDocs(e, "aIUsedInfo")
                                  }
                                />
                              </label>
                            </div>
                          </div>
                          {modalObject.uploaded_file_info.modelInfo.aIUsedInfo.dataFiles.map(
                            (item, indexChild) => (
                              <span
                                className="file-names"
                                key={indexChild + "aIUsedInfo"}
                              >
                                {item.fileName}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">10a.</span>
                        <div
                          className="d-flex flex-column gap5"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span className="general-title">Rigging</span>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AntSwitch
                              checked={
                                modalObject.uploaded_file_info.modelInfo.riggingInfo.isRigging
                              }
                              onChange={() =>
                                handleUpdateParentChildComposition(
                                  "riggingInfo",
                                  "isRigging",
                                  !modalObject.uploaded_file_info.modelInfo.riggingInfo.isRigging
                                )
                              }
                              inputProps={{ "aria-label": "ant design" }}
                            />
                            <Typography className="switch-title">
                              Model is Rigged
                            </Typography>
                          </Stack>
                          <span className="scene">
                            Description & Methodology
                          </span>
                          <div className="d-flex">
                            <textarea
                              value={
                                modalObject.uploaded_file_info.modelInfo.riggingInfo.description
                              }
                              onChange={(e) =>
                                handleUpdateParentChildComposition(
                                  "riggingInfo",
                                  "description",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="model-rigged-descriptions"
                              rows="3"
                              cols={4}
                              className="input-custom-textarea-upload"
                            />
                            <div>
                              <span className="or">and/or</span>
                              <label htmlFor="riggingInfoDocs">
                                <span className="upload-docs">
                                  Upload Docs
                                  <UploadIcon
                                    sx={{
                                      fontSize: "14px",
                                      margin: "0px 4px -2px 4px",
                                      color: "#505083",
                                    }}
                                  />
                                </span>
                                <input
                                  type="file"
                                  id="riggingInfoDocs"
                                  accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                  onChange={(e) =>
                                    handleSceneAddDocsAiDocs(e, "riggingInfo")
                                  }
                                />
                              </label>
                            </div>
                          </div>
                          {modalObject.uploaded_file_info.modelInfo.riggingInfo.dataFiles.map(
                            (item, indexChild) => (
                              <span
                                className="file-names"
                                key={indexChild + "riggingInfo"}
                              >
                                {item.fileName}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">10b.</span>
                        <div
                          className="d-flex flex-column gap5"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span className="general-title">Animation(s)</span>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AntSwitch
                              checked={
                                modalObject.uploaded_file_info.modelInfo.animationInfo.isAnimated
                              }
                              onChange={() =>
                                handleUpdateParentChildComposition(
                                  "animationInfo",
                                  "isAnimated",
                                  !modalObject.uploaded_file_info.modelInfo.animationInfo
                                    .isAnimated
                                )
                              }
                              inputProps={{ "aria-label": "ant design" }}
                            />
                            <Typography className="switch-title">
                              Is Animated
                            </Typography>
                          </Stack>
                          <span className="scene">
                            Animation Technology, Methodology, & Description
                          </span>
                          {modalObject.uploaded_file_info.modelInfo.animationInfo.animations.map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                  key={index + "animations"}
                                >
                                  {modalObject.uploaded_file_info.modelInfo.animationInfo
                                    .animations.length > 1 && (
                                      <ClearIcon
                                        className="custom-clear-icon"
                                        onClick={() =>
                                          handleDeleteAnimations(index)
                                        }
                                      />
                                    )}
                                  <div>
                                    <div
                                      className="data-urls"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label htmlFor="animations-name">
                                          Animation name
                                        </label>
                                        <input
                                          value={item.name}
                                          onChange={(e) =>
                                            handleChangeAnimations(
                                              "animations",
                                              index,
                                              "name",
                                              e.target.value
                                            )
                                          }
                                          type="text"
                                          id="animations-name"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label htmlFor="animations-fps">
                                          FPS
                                        </label>
                                        <input
                                          value={item.fps}
                                          onChange={(e) =>
                                            handleChangeAnimations(
                                              "animations",
                                              index,
                                              "fps",
                                              e.target.value
                                            )
                                          }
                                          type="number"
                                          id="animations-fps"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label htmlFor="animations-Total-Frames">
                                          Total Frames
                                        </label>
                                        <input
                                          value={item.totalFrames}
                                          onChange={(e) =>
                                            handleChangeAnimations(
                                              "animations",
                                              index,
                                              "totalFrames",
                                              e.target.value
                                            )
                                          }
                                          type="number"
                                          id="animations-Total-Frames"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label htmlFor="animations-Duration">
                                          Duration
                                        </label>
                                        <input
                                          value={item.duration}
                                          onChange={(e) =>
                                            handleChangeAnimations(
                                              "animations",
                                              index,
                                              "duration",
                                              e.target.value
                                            )
                                          }
                                          type="number"
                                          id="animations-Duration"
                                          className="input-custom-render"
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <textarea
                                        value={item.description}
                                        onChange={(e) =>
                                          handleChangeAnimations(
                                            "animations",
                                            index,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        type="text"
                                        id="model-animated-descriptions"
                                        rows="3"
                                        cols={4}
                                        className="input-custom-textarea-upload"
                                      />
                                      <div>
                                        <span className="or">and/or</span>
                                        <label
                                          htmlFor={`modelInfoDocs-${index}`}
                                        >
                                          <span className="upload-docs">
                                            Upload Docs
                                            <UploadIcon
                                              sx={{
                                                fontSize: "14px",
                                                margin: "0px 4px -2px 4px",
                                                color: "#505083",
                                              }}
                                            />
                                          </span>
                                          <input
                                            type="file"
                                            className="materials-docs"
                                            id={`modelInfoDocs-${index}`}
                                            accept=".pdf,.docs" /** user can only upload documents pdf or word file */
                                            onChange={(e) =>
                                              handleAddAnimationsDocs(e, index)
                                            }
                                          />
                                        </label>
                                      </div>
                                    </div>
                                    {item.dataFiles.map((item, indexChild) => (
                                      <span
                                        className="file-names"
                                        key={indexChild + "modelAnimated"}
                                      >
                                        {item.fileName}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              );
                            }
                          )}

                          <AddIcon
                            className="custom-add-icon"
                            onClick={handleAddAnimations}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          margin: "10px 0px",
                        }}
                      >
                        <span className="serial-no">12.</span>
                        <div
                          className="d-flex flex-column gap5"
                          style={{
                            width: "100%",
                          }}
                        >
                          <span className="general-title">
                            3d Model Goal & Methodology Overview 
                          </span>
                          <span className="scene">
                            (include any other text, question, intuitions, etc. about the modeled process, structure,system, or function to better contextualize or drice thought about your production)
                          </span>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <textarea
                              value={modalObject.uploaded_file_info.modelInfo.sceneDescription}
                              onChange={(e) =>
                                handleUpdateModelInfo(
                                  "sceneDescription",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="purpose-des"
                              rows="9"
                              cols={4}
                              className="input-custom-textarea-upload"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="upload-content-container">
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px" }}
                >
                  {/* <Typography variant="p" className="content-id">
                    Content ID:{" "}
                    <span style={{ fontWeight: 500 }}>{contentId || ""}</span>
                  </Typography> */}
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    {/* <Typography variant="p" className="content-owner">
                      Content Owner
                    </Typography> */}
                    {/* <div className="fields-icons">
                      {Array(5)
                        .fill("")
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames("disable-icon", {
                                "active-icon":
                                  modalObject.field_icon_view.includes(
                                    index + 1
                                  ),
                              })}
                              onClick={() => handleSelectFieldIcon(index + 1)}
                            >
                              {viewIcons[index + 1]}
                            </div>
                          );
                        })}
                    </div> */}
                  </div>
                  {/* <div className="avatar-cnt" style={{ marginTop: 0 }}>
                    <Avatar {...stringAvatar(fullName, 15, 15, 6)} />
                    <p className="user_name-upload">{fullName}</p>
                  </div> */}
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">13.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Model Biocomplexity
                      </span>
                      <div
                        className="data-urls"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="primary-layer">
                            Select Primary Layer
                          </label>
                          <select
                            id="ai-format"
                            className="custom-select"
                            style={{ marginTop: "2px", marginBottom: "0px" }}
                            value={modalObject.uploaded_file_info.scientificInfo.primaryLayer}
                            onChange={(e) =>
                              handleUpdateScientificInfo(
                                "primaryLayer",
                                e.target.value
                              )
                            }
                          >
                            <option value="" disabled className="custom-option">
                              Select layer
                            </option>
                            <option value="Biosphere" className="custom-option">
                              Biosphere
                            </option>
                            <option value="Biome" className="custom-option">
                              Biome
                            </option>
                            <option value="Community" className="custom-option">
                              Community
                            </option>
                            <option value="Ecosystem" className="custom-option">
                              Ecosystem
                            </option>
                            <option value="Organism" className="custom-option">
                              Organism
                            </option>
                            <option value="Organ" className="custom-option">
                              Organ
                            </option>
                            <option value="Tissue" className="custom-option">
                              Tissue
                            </option>
                            <option value="Cell" className="custom-option">
                              Cell
                            </option>
                            <option value="Oeganelle" className="custom-option">
                              Organelle
                            </option>
                            <option value="Biomolecule" className="custom-option">
                              Biomolecule
                            </option>
                            <option value="Molecule" className="custom-option">
                              Molecule
                            </option>
                            <option value="Material" className="custom-option">
                              Material
                            </option>
                            <option value="Atoms" className="custom-option">
                              Atom
                            </option>
                            <option value="Subatomic Particle" className="custom-option">
                              Subatomic Particle
                            </option>
                          </select>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="secondary-layer">
                            Select Secondary Layer(s)
                          </label>
                          <Select
                            multiple
                            value={modalObject.uploaded_file_info.scientificInfo.secondaryLayer}
                            onChange={handleSelectSecondaryLayer}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            className="custom-multi-select"
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <span>Select</span>;
                              }
                              return selected.join(", ");
                            }}
                          >
                            {secondaryLayer.map((item) => (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={multiSelectStyles}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">14.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Bioprocess / Molecular Function
                      </span>
                      <span className="scene">
                        Input GO ID, PUBMED ID, or other OBO Ontology vocabulary
                        for name of process depicted
                      </span>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="text"
                          value={inputVocabulary}
                          id="bioPress-name"
                          className="input-custom-render"
                          placeholder=""
                          onChange={(e) => setInputVocabulary(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleAddVocabulary(inputVocabulary);
                              setInputVocabulary("");
                            }
                          }}
                        />
                        <span
                          className="scene"
                          style={{ lineHeight: "23px", marginTop: "5px" }}
                        >
                          {modalObject.uploaded_file_info.scientificInfo.vocabulary.map(
                            (item, index) => {
                              return (
                                <span
                                  key={index + "vocabulary"}
                                  className="sci-col-item"
                                >
                                  {item}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px", marginTop: '100px' }}
                >
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">15.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Model Biological Structures & Components
                      </span>
                      <span className="scene">
                        Input biological structures & components (primary then
                        secondary layers) used to compose the scene
                      </span>

                      {modalObject.uploaded_file_info.scientificInfo.primaryLayer && (
                        <div className="data-urls">
                          <span
                            className="scene"
                            style={{ lineHeight: "23px", marginTop: "5px" }}
                          >
                            <span className="col-item">
                              {modalObject.uploaded_file_info.scientificInfo.primaryLayer}
                            </span>
                          </span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <input
                              value={modalObject.uploaded_file_info.scientificInfo.primaryValue}
                              onChange={(e) =>
                                handleUpdateScientificInfo(
                                  "primaryValue",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="data-url"
                              className="input-custom-render"
                              placeholder=""
                            />
                          </div>
                          <span className="or">or</span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <span className="upload-docs">
                              Upload .csv or .xlsx
                              <UploadIcon
                                sx={{
                                  fontSize: "14px",
                                  margin: "0px 4px -2px 4px",
                                  color: "#505083",
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      )}

                      {modalObject.uploaded_file_info.scientificInfo.secondaryValue.map(
                        (item, index) => {
                          return (
                            <div
                              className="data-urls"
                              key={index + "secondaryValue"}
                            >
                              {modalObject.uploaded_file_info.scientificInfo.secondaryValue
                                .length > 1 && (
                                  <ClearIcon
                                    className="custom-clear-icon"
                                    onClick={() =>
                                      handleDeleteSecondaryVal(
                                        "secondaryValue",
                                        index
                                      )
                                    }
                                  />
                              )}
                              <span
                                className="scene"
                                style={{ lineHeight: "23px", marginTop: "5px" }}
                              >
                                <span className="col-item">{item.key}</span>
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <input
                                  value={item.value}
                                  onChange={(e) =>
                                    handleUpdateSecondaryVal(
                                      e.target.value,
                                      "value",
                                      index,
                                      "secondaryValue"
                                    )
                                  }
                                  type="text"
                                  id="data-url"
                                  className="input-custom-render"
                                  placeholder=""
                                />
                              </div>
                              <span className="or">or</span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <span className="upload-docs">
                                  Upload .csv or .xlsx
                                  <UploadIcon
                                    sx={{
                                      fontSize: "14px",
                                      margin: "0px 4px -2px 4px",
                                      color: "#505083",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px", marginTop: '85px' }}
                >
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">16.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Published In
                      </span>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          checked={modalObject.uploaded_file_info.scientificInfo.isPublished}
                          onChange={() =>
                            handleUpdateScientificInfo(
                              "isPublished",
                              !modalObject.uploaded_file_info.scientificInfo.isPublished
                            )
                          }
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography className="switch-title">
                          Published
                        </Typography>
                      </Stack>
                      {modalObject.uploaded_file_info.scientificInfo.publishedInfo.map(
                        (item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                              key={index + "publishedInfo"}
                            >
                              {modalObject.uploaded_file_info.scientificInfo.publishedInfo.length >
                                1 && (
                                  <ClearIcon
                                    className="custom-clear-icon"
                                    onClick={() =>
                                      handleDeletePublishedInfo(index)
                                    }
                                  />
                                )}
                              <div className="data-urls">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="journal">Journal</label>
                                  <input
                                    value={item.name}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "name",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="journal"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="journal-title">Title</label>
                                  <input
                                    value={item.title}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "title",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="journal-title"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="doi-title">DOI</label>
                                  <input
                                    value={item.doi}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "doi",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="doi-title"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                      <AddIcon
                        className="custom-add-icon"
                        onClick={handleAddPublishedInfo}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">17.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Reference Database(s)
                      </span>
                      <Select
                        multiple
                        value={modalObject.uploaded_file_info.scientificInfo.referenceDatabases}
                        onChange={handleSelectChangeDatabases}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        className="custom-multi-select"
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select</span>;
                          }
                          return selected.join(", ");
                        }}
                      >
                        {imagingArray.map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                            sx={multiSelectStyles}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">18.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        3D Model Goal & Methodology Overview
                      </span>
                      <span className="scene">
                        {" "}
                        (Include any other text, questions, intuitions, etc.
                        about the modelled process, structure, system, or
                        function to better contextualize or drive thought about
                        your production .
                      </span>
                      <textarea
                        value={
                          modalObject.uploaded_file_info.scientificInfo.methodologyDescription
                        }
                        onChange={(e) =>
                          handleUpdateScientificInfo(
                            "methodologyDescription",
                            e.target.value
                          )
                        }
                        type="text"
                        id="methodology_descriptions"
                        rows="8"
                        cols={8}
                        className="input-custom-textarea-upload"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">19.</span>
                    <div className="d-flex flex-column gap5">
                      <div style={{ display: "flex", gap: "15px" }}>
                        <span className="general-title">
                          Content Preview
                        </span>
                        <label htmlFor="product-preview-docs">
                          <span className="upload-docs">
                            Upload Docs
                            <UploadIcon
                              sx={{
                                fontSize: "14px",
                                margin: "0px 4px -2px 4px",
                                color: "#505083",
                                width: "fit-content",
                              }}
                            />
                          </span>
                          <input
                            type="file"
                            accept=".png,.jpeg,.jpg,.mov,.mp4"
                            id="product-preview-docs"
                            ref={fileInputRef}
                            onChange={handleAddDocsProductPreview}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="upload-content-container">
       
              <Grid container mt={2} spacing={2}>
                <Grid item md={2} sm={4} xs={12}>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">20.</span>
                    <div className="d-flex flex-column gap5">
                      <span className="general-title">
                        Who can See?
                      </span>
                      <span className="scene" style={{ lineHeight: "23px" }}>
                        <span
                          className={classNames("col-item", {
                            "unit-style-selected":
                              modalObject.uploaded_file_info.modelInfo.isViewParty === "public",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("isViewParty", "public")
                          }
                        >
                          Public
                        </span>
                        <span
                          className={classNames("sci-col-item", {
                            "unit-style-selected":
                              modalObject.uploaded_file_info.modelInfo.isViewParty === "private",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("isViewParty", "private")
                          }
                        >
                          Private
                        </span>
                      </span>

                      <span className="general-title" style={{marginTop:"8px"}}>
                        Download
                      </span>
                      <span className="scene" style={{ lineHeight: "23px" }}>
                        <span
                          className={classNames("col-item", {
                            "unit-style-selected":
                              modalObject?.uploaded_file_info?.modelInfo?.download === true,
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("download", true)
                          }
                        >
                          Enabled
                        </span>
                        <span
                          className={classNames("sci-col-item", {
                            "unit-style-selected":
                            modalObject?.uploaded_file_info?.modelInfo?.download === false,
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("download", false)
                          }
                        >
                          Disabled
                        </span>
                      </span>

                      {/* <span className="general-title">Age Restricted</span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          value={modalObject.modelInfo.ageInfo.months}
                          onChange={(e) =>
                            handleUpdateParentChildComposition(
                              "ageInfo",
                              "months",
                              e.target.value
                            )
                          }
                          type="number"
                          id="age-max"
                          className="input-custom-render"
                          placeholder=""
                        />
                        <span className="or">-</span>
                        <input
                          value={modalObject.modelInfo.ageInfo.years}
                          onChange={(e) =>
                            handleUpdateParentChildComposition(
                              "ageInfo",
                              "years",
                              e.target.value
                            )
                          }
                          type="number"
                          id="age-min"
                          className="input-custom-render"
                          placeholder=""
                        />
                        <span className="or">years old</span>
                      </div> */}
                    </div>
                  </div>
                </Grid>
                <Grid item md={6} sm={8} xs={12}>
                <div style={{ display: "flex", gap: "6px", margin: "10px 0px" }}>
                <span className="serial-no">21. </span>
                <div className="d-flex flex-column gap5">
                  <span className="general-title">
                    {" "}
                    Licensing Selection
                  </span>
                </div>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="licensce-cnt">
                      <Radio
                        checked
                        sx={{
                          color: "#505083",
                          "&.Mui-checked": {
                            color: "#505083",
                          },
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        size="medium"
                      />
                      <div
                        className="d-flex flex-column gap5"
                        style={{ position: "relative", width: "100%" }}
                      >
                        <div style={{ position: "absolute", right: "0px" }}>
                          <ImportantIcon />
                        </div>
                        <span className="general-title">Creative Commons</span>
                        <div style={{ display: "flex", gap: "12px" }}>
                          <Select
                            displayEmpty
                            value={modalObject?.uploaded_file_info?.modelInfo?.licenseInfo || ''}
                            onChange={handleSelectLicense}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            className="custom-multi-select"
                            renderValue={(selected) => {
                              if (!selected) {
                                return <span>Select</span>;
                              }
                              return selected;
                            }}
                          >
                            {licenseType.map((item) => (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={multiSelectStyles}
                              >
                                {item}
                                <LightTooltip
                                  title={
                                    <span>
                                      {tooltipDescription[item]} <br />{" "}
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={tooltipLink[item]}
                                        style={{ color: "white" }}
                                      >
                                        {tooltipLink[item]}
                                      </a>{" "}
                                    </span>
                                  }
                                  placement="top"
                                >
                                  <span>
                                    <Question />
                                  </span>
                                </LightTooltip>
                              </MenuItem>
                            ))}
                          </Select>
                          <span className="scene">
                            Creative Commons licenses give everyone from
                            individual creators to large institutions a
                            standardized way to grant the public permission to use
                            their creative work under copyright law. From the
                            reuser’s perspective, the presence of a Creative
                            Commons license on a copyrighted work answers the
                            question, What can I do with this work?
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                </Grid>
                <Grid item md={2} sm={4} xs={12}>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <div className="d-flex flex-column gap5">
                      {/* <span className="general-title">
                        Availability
                      </span>
                      <span className="scene" style={{ lineHeight: "23px" }}>
                        <span
                          className={classNames("col-item", {
                            "unit-style-selected":
                              modalObject.modelInfo.availability === "free",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("availability", "free")
                          }
                        >
                           
                        </span>
                        <span
                          className={classNames("sci-col-item", {
                            "unit-style-selected":
                              modalObject.modelInfo.availability === "paid",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo("availability", "paid")
                          }
                        >
                          
                        </span>
                      </span> */}
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="price">Price $</label>
                        <input
                          value={modalObject.modelInfo.price}
                          onChange={(e) =>
                            handleUpdateModelInfo("price", e.target.value)
                          }
                          type="text"
                          id="price"
                          className="input-custom-render"
                          placeholder=""
                        />
                      </div> */}
                    </div>
                    <div className="d-flex flex-column gap5">
                      <span className="scene">
                        Would you like to allow Biological Information OS Inc.
                        to integrate your model in later platform features?
                      </span>
                      <span className="scene" style={{ lineHeight: "23px" }}>
                        <span
                          className={classNames("col-item", {
                            "unit-style-selected":
                              modalObject.uploaded_file_info.modelInfo
                                .isIntegrationWithPlatform === "yes",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo(
                              "isIntegrationWithPlatform",
                              "yes"
                            )
                          }
                        >
                          Yes
                        </span>
                        <span
                          className={classNames("sci-col-item", {
                            "unit-style-selected":
                              modalObject.uploaded_file_info.modelInfo
                                .isIntegrationWithPlatform === "later",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo(
                              "isIntegrationWithPlatform",
                              "later"
                            )
                          }
                        >
                          Ask at a later date
                        </span>
                        <span
                          className={classNames("sci-col-item", {
                            "unit-style-selected":
                              modalObject.uploaded_file_info.modelInfo
                                .isIntegrationWithPlatform === "no",
                          })}
                          onClick={() =>
                            handleUpdateModelInfo(
                              "isIntegrationWithPlatform",
                              "no"
                            )
                          }
                        >
                          No
                        </span>
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        )}
        {selectedView === "sciInfo" && (
          <Fragment>
            <div className="upload-content-container">
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px" }}
                >
                  <Typography variant="p" className="content-id">
                    Content ID:{" "}
                    <span style={{ fontWeight: 500 }}>{contentId || ""}</span>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="p" className="content-owner">
                      Content Owner
                    </Typography>
                    <div className="fields-icons">
                      {Array(5)
                        .fill("")
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={classNames("disable-icon", {
                                "active-icon":
                                  modalObject?.uploaded_file_info.field_icon_view.includes(
                                    index + 1
                                  ),
                              })}
                              onClick={() => {
                                // console.log("contentId", contentId)
                                // if(!contentId){
                                  // handleSelectFieldIcon(index + 1)
                                // }
                              }}
                            >
                              {viewIcons[index + 1]}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="avatar-cnt" style={{ marginTop: 0 }}>
                    <Avatar {...stringAvatar(fullName, 15, 15, 6)} />
                    <p className="user_name-upload">{fullName}</p>
                  </div>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">1.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Model Biocomplexity
                      </span>
                      <div
                        className="data-urls"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="primary-layer">
                            Select Primary Layer
                          </label>
                          <select
                            id="ai-format"
                            className="custom-select"
                            style={{ marginTop: "2px", marginBottom: "0px" }}
                            value={modalObject.uploaded_file_info.scientificInfo.primaryLayer}
                            onChange={(e) =>
                              handleUpdateScientificInfo(
                                "primaryLayer",
                                e.target.value
                              )
                            }
                          >
                            <option value="" disabled className="custom-option">
                              Select layer
                            </option>
                            <option value="Biosphere" className="custom-option">
                              Biosphere
                            </option>
                            <option value="Biome" className="custom-option">
                              Biome
                            </option>
                            <option value="Community" className="custom-option">
                              Community
                            </option>
                            <option value="Ecosystem" className="custom-option">
                              Ecosystem
                            </option>
                            <option value="Organism" className="custom-option">
                              Organism
                            </option>
                            <option value="Organ" className="custom-option">
                              Organ
                            </option>
                            <option value="Tissue" className="custom-option">
                              Tissue
                            </option>
                            <option value="Cell" className="custom-option">
                              Cell
                            </option>
                            <option value="Oeganelle" className="custom-option">
                              Organelle
                            </option>
                            <option value="Biomolecule" className="custom-option">
                              Biomolecule
                            </option>
                            <option value="Molecule" className="custom-option">
                              Molecule
                            </option>
                            <option value="Material" className="custom-option">
                              Material
                            </option>
                            <option value="Atoms" className="custom-option">
                              Atom
                            </option>
                            <option value="Subatomic Particle" className="custom-option">
                              Subatomic Particle
                            </option>
                          </select>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label htmlFor="secondary-layer">
                            Select Secondary Layer(s)
                          </label>
                          <Select
                            multiple
                            value={modalObject.uploaded_file_info.scientificInfo.secondaryLayer}
                            onChange={handleSelectSecondaryLayer}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            className="custom-multi-select"
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <span>Select</span>;
                              }
                              return selected.join(", ");
                            }}
                          >
                            {secondaryLayer.map((item) => (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={multiSelectStyles}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">2.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Bioprocess / Molecular Function
                      </span>
                      <span className="scene">
                        Input GO ID, PUBMED ID, or other OBO Ontology vocabulary
                        for name of process depicted
                      </span>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="text"
                          value={inputVocabulary}
                          id="bioPress-name"
                          className="input-custom-render"
                          placeholder=""
                          onChange={(e) => setInputVocabulary(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleAddVocabulary(inputVocabulary);
                              setInputVocabulary("");
                            }
                          }}
                        />
                        <span
                          className="scene"
                          style={{ lineHeight: "23px", marginTop: "5px" }}
                        >
                          {modalObject.uploaded_file_info.scientificInfo.vocabulary.map(
                            (item, index) => {
                              return (
                                <span
                                  key={index + "vocabulary"}
                                  className="sci-col-item"
                                >
                                  {item}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px", marginTop: '100px' }}
                >
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">3.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Model Biological Structures & Components
                      </span>
                      <span className="scene">
                        Input biological structures & components (primary then
                        secondary layers) used to compose the scene
                      </span>

                      {modalObject.uploaded_file_info.scientificInfo.primaryLayer && (
                        <div className="data-urls">
                          <span
                            className="scene"
                            style={{ lineHeight: "23px", marginTop: "5px" }}
                          >
                            <span className="col-item">
                              {modalObject.uploaded_file_info.scientificInfo.primaryLayer}
                            </span>
                          </span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <input
                              value={modalObject.uploaded_file_info.scientificInfo.primaryValue}
                              onChange={(e) =>
                                handleUpdateScientificInfo(
                                  "primaryValue",
                                  e.target.value
                                )
                              }
                              type="text"
                              id="data-url"
                              className="input-custom-render"
                              placeholder=""
                            />
                          </div>
                          <span className="or">or</span>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <span className="upload-docs">
                              Upload .csv or .xlsx
                              <UploadIcon
                                sx={{
                                  fontSize: "14px",
                                  margin: "0px 4px -2px 4px",
                                  color: "#505083",
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      )}

                      {modalObject.uploaded_file_info.scientificInfo.secondaryValue.map(
                        (item, index) => {
                          return (
                            <div
                              className="data-urls"
                              key={index + "secondaryValue"}
                            >
                              <span
                                className="scene"
                                style={{ lineHeight: "23px", marginTop: "5px" }}
                              >
                                <span className="col-item">{item.key}</span>
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <input
                                  value={item.value}
                                  onChange={(e) =>
                                    handleUpdateSecondaryVal(
                                      e.target.value,
                                      "value",
                                      index,
                                      "secondaryValue"
                                    )
                                  }
                                  type="text"
                                  id="data-url"
                                  className="input-custom-render"
                                  placeholder=""
                                />
                              </div>
                              <span className="or">or</span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <span className="upload-docs">
                                  Upload .csv or .xlsx
                                  <UploadIcon
                                    sx={{
                                      fontSize: "14px",
                                      margin: "0px 4px -2px 4px",
                                      color: "#505083",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "6px", marginTop: '85px' }}
                >
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">4.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Published In
                      </span>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          checked={modalObject.uploaded_file_info.scientificInfo.isPublished}
                          onChange={() =>
                            handleUpdateScientificInfo(
                              "isPublished",
                              !modalObject.uploaded_file_info.scientificInfo.isPublished
                            )
                          }
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography className="switch-title">
                          Published
                        </Typography>
                      </Stack>
                      {modalObject.uploaded_file_info.scientificInfo.publishedInfo.map(
                        (item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                              key={index + "publishedInfo"}
                            >
                              {modalObject.uploaded_file_info.scientificInfo.publishedInfo.length >
                                1 && (
                                  <ClearIcon
                                    className="custom-clear-icon"
                                    onClick={() =>
                                      handleDeletePublishedInfo(index)
                                    }
                                  />
                                )}
                              <div className="data-urls">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="journal">Journal</label>
                                  <input
                                    value={item.name}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "name",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="journal"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="journal-title">Title</label>
                                  <input
                                    value={item.title}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "title",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="journal-title"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  <label htmlFor="doi-title">DOI</label>
                                  <input
                                    value={item.doi}
                                    onChange={(e) =>
                                      handleUpdateSecondaryVal(
                                        e.target.value,
                                        "doi",
                                        index,
                                        "publishedInfo"
                                      )
                                    }
                                    type="text"
                                    id="doi-title"
                                    className="input-custom-render"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                      <AddIcon
                        className="custom-add-icon"
                        onClick={handleAddPublishedInfo}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "10px 0px" }}
                  >
                    <span className="serial-no">5.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        Reference Database(s)
                      </span>
                      <Select
                        multiple
                        value={modalObject.uploaded_file_info.scientificInfo.referenceDatabases}
                        onChange={handleSelectChangeDatabases}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        className="custom-multi-select"
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Select</span>;
                          }
                          return selected.join(", ");
                        }}
                      >
                        {imagingArray.map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                            sx={multiSelectStyles}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    style={{ display: "flex", gap: "6px", margin: "0px 0px 10px 0px" }}
                  >
                    <span className="serial-no">6.</span>
                    <div
                      className="d-flex flex-column gap5"
                      style={{ width: "100%" }}
                    >
                      <span className="general-title">
                        3D Model Goal & Methodology Overview
                      </span>
                      <span className="scene">
                        {" "}
                        (Include any other text, questions, intuitions, etc.
                        about the modelled process, structure, system, or
                        function to better contextualize or drive thought about
                        your production .
                      </span>
                      <textarea
                        value={
                          modalObject.uploaded_file_info.scientificInfo.methodologyDescription
                        }
                        onChange={(e) =>
                          handleUpdateScientificInfo(
                            "methodologyDescription",
                            e.target.value
                          )
                        }
                        type="text"
                        id="methodology_descriptions"
                        rows="8"
                        cols={8}
                        className="input-custom-textarea-upload"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        )}
      </Dialog>
      {snackbarComponent()}
    </>
  );
};

const tooltipDescription = {
  "CC 0": "No Copyright",
  "CC BY":
    "Free to Share & Adapt even commercially, but must have credit attributed",
  "CC BY-SA":
    "Free to Share & Adapt and must attribute credit appropriately, but can only do so if the created work is provided under the same license as the original work",
  "CC BY-ND":
    "Free to Share & Adapt but must have credit attributed, & may not be used for commercial purposes",
  "CC BY-NC":
    "Free to Share & Adapt and must attribute credit appropriately, but can only do so if the created work is provided under the same license as the original work, and may not be produced commercially",
  "CC BY-NC-SA":
    "Free to Share, but must have credit attributed and no derivatives of the work are allowed",
  "CC BY-NC-ND":
    "Free to Share, but must have credit attributed. No derivatives of the work are allowed & there is no commercial use allowed",
};

const tooltipLink = {
  "CC 0": "https://creativecommons.org/publicdomain/zero/1.0/",
  "CC BY": "https://creativecommons.org/licenses/by/4.0/",
  "CC BY-SA": "https://creativecommons.org/licenses/by-sa/4.0/",
  "CC BY-ND": "https://creativecommons.org/licenses/by-nc/4.0/",
  "CC BY-NC": "https://creativecommons.org/licenses/by-nc-sa/4.0/",
  "CC BY-NC-SA": "https://creativecommons.org/licenses/by-nd/4.0/",
  "CC BY-NC-ND": "https://creativecommons.org/licenses/by-nc-nd/4.0/",
};
const viewIcons = {
  1: (
    <LightTooltip
      title="Model based on some image & clearly depicts a structure or process"
      placement="top"
    >
      <span>
        <SvgOneIcon />
      </span>
    </LightTooltip>
  ),
  2: (
    <LightTooltip
      title="Model based on composited Technical Imaging"
      placement="top"
    >
      <span>
        <SvgTwoIcon />
      </span>
    </LightTooltip>
  ),
  3: (
    <LightTooltip title="Citation used to further edit & model" placement="top">
      <span>
        <SvgThreeIcon />
      </span>
    </LightTooltip>
  ),
  4: (
    <LightTooltip
      title="Academic or Scientific Research Partnership"
      placement="top"
    >
      <span>
        <SvgFourIcon />
      </span>
    </LightTooltip>
  ),
  5: (
    <LightTooltip title="Published in Peer-Reviewed Journal" placement="top">
      <span>
        <SvgFiveIcon />
      </span>
    </LightTooltip>
  ),
};
export default UploadDialog;
